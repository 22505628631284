import React, { useState } from "react";
import {
  Autocomplete,
  TextField,
  Grid,
  Box,
  Typography,
  MenuItem,
} from "@mui/material";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { MdLocationOn } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { IoSearch } from "react-icons/io5";
import { selectStyles } from "../_elements/SelectStyles";
import { useStateContext } from "../../context/ContextProvider";
import Select from "react-select";
const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "300px",
};

const locationInclude = [
  {
    label: "Include",
    value: 1,
  },
  {
    label: "Exlude",
    value: 2,
  },
];
const center = { lat: 30.3753, lng: 69.3451 }; // Example center: Pakistan

const LocationSelector = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API, // Replace with your API key
    libraries,
  });
  const { currentMode, primaryColor } = useStateContext();

  const [locations, setLocations] = useState([
    { name: "Charsadda Sherpao", radius: "2 km", lat: 34.145, lng: 71.742 },
  ]);

  const [newLocation, setNewLocation] = useState({ name: "", radius: "2 km" });

  const handleLocationChange = (event, value) => {
    if (value) {
      setNewLocation({ ...newLocation, name: value });
    }
  };

  const handleRadiusChange = (event) => {
    setNewLocation({ ...newLocation, radius: event.target.value });
  };

  const handleAddLocation = () => {
    if (newLocation.name) {
      setLocations([...locations, newLocation]);
      setNewLocation({ name: "", radius: "2 km" });
    }
  };

  const handleMapClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setNewLocation({ ...newLocation, lat, lng });
  };

  return (
    <Box sx={{ p: 2, maxWidth: 800 }}>
      <label htmlFor="" className="text-[14px] font-semibold mb-3">
        Locations
      </label>
      <p>Reach people living in or recently in this location.</p>
      <div
        className={` ${
          currentMode === "dark" ? "bg-gray-700" : "bg-[#F2F2F2]"
        } rounded-lg py-3 px-4`}
      >
        <label htmlFor="" className="text-[14px] mb-3">
          Pakistan
        </label>
        <div
          className={` ${
            currentMode === "dark" ? "bg-black" : "bg-white"
          } flex justify-between items-center px-3 rounded-lg py-3 group`}
        >
          <div className="flex gap-4 items-center">
            <MdLocationOn color="green" size={16} />
            Charsadda Sherpao
          </div>
          <span className="group-hover:block hidden cursor-pointer">
            <RxCross2 />
          </span>
        </div>
      </div>
      <div className="flex items-center gap-3 mt-3">
        <div className="w-[150px]">
          <Select
            // isLoading={adSetLoading}
            id="include-exlude"
            options={locationInclude}
            // value={adDetails?.publicProfile}
            // onChange={(e) => {
            //   setAdDetails((pre) => ({
            //     ...pre,
            //     publicProfile: e,
            //   }));
            // }}
            placeholder={""}
            className={`w-full mb-0`}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />
        </div>

        <div className="flex items-center gap-4 border-b py-5 px-7 flex-1 mb-5">
          <IoSearch size={16} />

          <input
            type="text"
            className={`focus:outline-none border-none flex-1 ${
              currentMode === "dark" ? "bg-dark" : "bg-white"
            }`}
            placeholder="Search Locations"
            // onChange={(e) => setSearchTerm(e?.target?.value)}
            // value={searchTerm}
          />
        </div>
      </div>
      {/* Map */}
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={4}
          center={center}
          onClick={handleMapClick}
        >
          {locations.map((loc, index) => (
            <Marker key={index} position={{ lat: loc.lat, lng: loc.lng }} />
          ))}
        </GoogleMap>
      ) : (
        <Typography>Loading map...</Typography>
      )}
    </Box>
  );
};

export default LocationSelector;
