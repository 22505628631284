// import { Grid, Box } from "@mui/material";
// import { useState } from "react";

// const Scheduler = () => {
//   const [selectedSlots, setSelectedSlots] = useState([]);

//   const handleSlotClick = (day, hour) => {
//     const slot = `${day}-${hour}`;
//     setSelectedSlots((prev) =>
//       prev.includes(slot) ? prev.filter((s) => s !== slot) : [...prev, slot]
//     );
//   };

//   const days = [
//     "Monday",
//     "Tuesday",
//     "Wednesday",
//     "Thursday",
//     "Friday",
//     "Saturday",
//     "Sunday",
//   ];
//   const hours = Array.from({ length: 24 }, (_, i) => `${i}:00`);

//   return (
//     <Grid container>
//       {days.map((day) => (
//         <Grid item xs={12} key={day}>
//           <Box>{day}</Box>
//           <Grid container>
//             {hours.map((hour) => (
//               <Grid
//                 item
//                 key={`${day}-${hour}`}
//                 xs={1}
//                 onClick={() => handleSlotClick(day, hour)}
//                 sx={{
//                   border: "1px solid gray",
//                   backgroundColor: selectedSlots.includes(`${day}-${hour}`)
//                     ? "blue"
//                     : "white",
//                   cursor: "pointer",
//                 }}
//               >
//                 {hour}
//               </Grid>
//             ))}
//           </Grid>
//         </Grid>
//       ))}
//     </Grid>
//   );
// };

// export default Scheduler;
import React, { useState } from "react";
import { Grid, Typography, Box } from "@mui/material";

const TimeSelector = () => {
  // State to track selected cells
  const [selectedCells, setSelectedCells] = useState([]);

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const hours = [
    "12 am",
    "3 am",
    "6 am",
    "9 am",
    "12 pm",
    "3 pm",
    "6 pm",
    "9 pm",
  ];

  // Handle cell click
  const handleCellClick = (day, hour) => {
    const cellId = `${day}-${hour}`;
    setSelectedCells((prev) =>
      prev.includes(cellId)
        ? prev.filter((id) => id !== cellId)
        : [...prev, cellId]
    );
  };

  return (
    <Box sx={{ padding: 2, width: "100%", maxWidth: 800 }}>
      {/* Grid Header */}
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <Typography></Typography>
        </Grid>
        {hours.map((hour) => (
          <Grid item xs={1} key={hour}>
            <Typography variant="body2" align="center">
              {hour}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {/* Time Slots Grid */}
      {days.map((day) => (
        <Grid container spacing={0} key={day}>
          <Grid item xs={2}>
            <Typography variant="body2">{day}</Typography>
          </Grid>
          {hours.map((hour) => {
            const cellId = `${day}-${hour}`;
            return (
              <Grid
                item
                xs={1}
                key={cellId}
                sx={{
                  border: "1px solid lightgray",
                  height: 30,
                  backgroundColor: selectedCells.includes(cellId)
                    ? "lightblue"
                    : "white",
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "lightgray" },
                }}
                onClick={() => handleCellClick(day, hour)}
              />
            );
          })}
        </Grid>
      ))}
    </Box>
  );
};

export default TimeSelector;
