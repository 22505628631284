// import React, { useState } from "react";
// import { TreeView, TreeItem } from "@mui/lab";
// import { Checkbox, FormControlLabel } from "@mui/material";
// // import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// // import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";

// const HierarchicalTree = () => {
//   const [checkedItems, setCheckedItems] = useState({});

//   const handleToggle = (id) => {
//     setCheckedItems((prev) => ({
//       ...prev,
//       [id]: !prev[id],
//     }));
//   };

//   const renderTree = (nodes) => (
//     <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
//       {Array.isArray(nodes.children)
//         ? nodes.children.map((node) => renderTree(node))
//         : nodes.checkbox && (
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={checkedItems[nodes.id] || false}
//                   onChange={() => handleToggle(nodes.id)}
//                 />
//               }
//               label=""
//             />
//           )}
//     </TreeItem>
//   );

//   const treeData = [
//     {
//       id: "1",
//       name: "Parent 1",
//       children: [
//         {
//           id: "2",
//           name: "Child 1.1",
//           children: [
//             { id: "3", name: "Subchild 1.1.1", checkbox: true },
//             { id: "4", name: "Subchild 1.1.2", checkbox: true },
//           ],
//         },
//       ],
//     },
//     {
//       id: "5",
//       name: "Parent 2",
//       children: [
//         { id: "6", name: "Child 2.1", checkbox: true },
//         { id: "7", name: "Child 2.2", checkbox: true },
//       ],
//     },
//   ];

//   return (
//     <TreeView
//       defaultCollapseIcon={<AiOutlineDown />}
//       defaultExpandIcon={<AiOutlineRight />}
//     >
//       {treeData.map((tree) => renderTree(tree))}
//     </TreeView>
//   );
// };

// export default HierarchicalTree;

import React, { useState } from "react";

const HierarchicalTree = () => {
  const [openNodes, setOpenNodes] = useState({});
  const [checkedItems, setCheckedItems] = useState({});

  const toggleNode = (id) => {
    setOpenNodes((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleToggle = (id) => {
    setCheckedItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const renderTree = (nodes, level = 0) => (
    <div className={`ml-${level * 4} border-l pl-4`}>
      <div
        className="cursor-pointer flex items-center space-x-2 w-full "
        onClick={() => toggleNode(nodes.id)}
      >
        {nodes.children && <span>{openNodes[nodes.id] ? "▼" : "▶"}</span>}
        <span>{nodes.name}</span>
        {!nodes.children && nodes.checkbox && (
          <input
            type="checkbox"
            checked={checkedItems[nodes.id] || false}
            onChange={() => handleToggle(nodes.id)}
          />
        )}
      </div>
      {openNodes[nodes.id] && nodes.children && (
        <div>{nodes.children.map((child) => renderTree(child, level + 1))}</div>
      )}
    </div>
  );

  const treeData = [
    {
      id: "1",
      name: "Parent 1",
      children: [
        {
          id: "2",
          name: "Child 1.1",
          children: [
            { id: "3", name: "Subchild 1.1.1", checkbox: true },
            { id: "4", name: "Subchild 1.1.2", checkbox: true },
          ],
        },
      ],
    },
    {
      id: "5",
      name: "Parent 2",
      children: [
        { id: "6", name: "Child 2.1", checkbox: true },
        { id: "7", name: "Child 2.2", checkbox: true },
      ],
    },
  ];

  return <div>{treeData.map((tree) => renderTree(tree))}</div>;
};

export default HierarchicalTree;
