import React, { useEffect, useState, useRef } from "react";

import { toast } from "react-toastify";
import { AiOutlineHistory } from "react-icons/ai";
import {
  TextField,
  CircularProgress,
  Tooltip,
  Modal,
  Backdrop,
  InputAdornment,
  FormControlLabel,
  Switch,
  Checkbox,
  Box,
} from "@mui/material";
import moment from "moment/moment";
import { IoIosShareAlt } from "react-icons/io";
import Select from "react-select";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "../../axoisConfig";
import usePermission from "../../utils/usePermission";
import { useStateContext } from "../../context/ContextProvider";
import CustomSwitch from "./customSwitch";
import { MdClose } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ImagePicker from "./ImagePicker";
import { selectStyles } from "../_elements/SelectStyles";
import Loader from "../Loader";
import MediaFiles from "./mediaFiles";
import { FaBatteryFull, FaSignal } from "react-icons/fa";
import { IoIosWifi } from "react-icons/io";
import { BsThreeDotsVertical } from "react-icons/bs";
const style = {
  transform: "translate(0%, 0%)",
  boxShadow: 24,
};
const stripeStyle = {
  backgroundColor: "#e5e7eb", // Light gray background
  backgroundImage:
    "linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent)",
  backgroundSize: "20px 20px", // Size of the stripes
};
const token = localStorage?.getItem("auth-token");

const publicProfiles = [
  { value: "69e1d633-a9ea-4b05-ba60-652da4edb8bb", label: "HIKAL REAL ESTATE" },
  {
    value: "8a0e4c35-89af-49ef-ba42-2a5dc9e7f647",
    label: "MARAHEB CLEANING SERVICES",
  },
  { value: "309c5bd8-e716-4d49-8729-028454221a47", label: "Tanatel UAE" },
];

const SingleAd = ({
  isSingleAd,
  setIsSingleAd,
  currentAdDetails,
  setCurrentAdDetails,
  getAllAds,
}) => {
  const {
    isLangRTL,
    BACKEND_URL,
    i18n,
    currentMode,
    t,
    snapchatCredentials,
    themeBgImg,
    darkModeColors,
    primaryColor,
  } = useStateContext();
  const [adDetails, setAdDetails] = useState();
  const [isClosing, setIsClosing] = useState(false);
  const [isChangesOccured, setIsChangesOccured] = useState(false);
  const [imagePickerModal, setImagePickerModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMediaFilesModal, setIsMediaFilesModal] = useState(false);

  const handleClose = () => {
    setIsClosing(true);

    setTimeout(() => {
      setIsClosing(false);
      setIsSingleAd(false);
    }, 1000);
  };

  useEffect(() => {
    if (currentAdDetails) {
      const { name, top_snap_media_id, status, creative_id } = currentAdDetails;

      setAdDetails({
        name,
        topSnapMediaId: top_snap_media_id,
        status: status === "ACTIVE",
      });
      fetchCreativeDetails(creative_id);
    } else {
      setAdDetails({
        name: "",
        shareable: false,
        brandName: "",
        topSnapMediaId: null,
        headLine: "",
        status: false,
        media_url: null,
        publicProfile: null,
      });
    }
  }, [currentAdDetails]);

  useEffect(() => {
    if (currentAdDetails && adDetails) {
      const { name, status } = currentAdDetails;
      const { name: Name, status: Status } = adDetails;
      if (Name != name || Status != (status == "ACTIVE")) {
        setIsChangesOccured(true);
      } else {
        setIsChangesOccured(false);
      }
    }
  }, [adDetails]);

  async function fetchCreativeDetails(id) {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/get-specific-creative`,
        {
          access_token: snapchatCredentials?.access_token,
          creative_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      const {
        headline,
        brand_name,
        shareable,
        review_status_details,
        review_status,
        top_snap_media_id,
        profile_properties,
      } = res?.data?.data?.creatives[0]?.creative;
      const media = await fetchMediaDetails(top_snap_media_id);
      setAdDetails((pre) => ({
        ...pre,
        shareable: shareable,
        brandName: brand_name,
        topSnapMediaId: top_snap_media_id,
        headLine: headline,
        media_url: media?.download_link,
        mediaType: media?.type,
        publicProfile: publicProfiles?.find(
          (profile) => profile?.value === profile_properties?.profile_id
        ),
      }));
    } catch (error) {
      toast.error("Unable to load ad details", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(error, "this is an error");
    } finally {
      setLoading(false);
    }
  }
  // async function fetchMediaDetails(id) {
  //   try {
  //     const res = await axios.post(
  //       `${BACKEND_URL}/snapchat/preview-specific-media`,
  //       {
  //         access_token: snapchatCredentials?.access_token,
  //         media_id: id,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     );

  //     return res?.data?.data?.link;
  //   } catch (error) {
  //     toast.error("Unable to load ad details", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     console.log(error, "this is an error");
  //   }
  // }
  async function fetchMediaDetails(id) {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/specific-media`,
        {
          access_token: snapchatCredentials?.access_token,
          media_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      return res?.data?.data?.media[0]?.media;
    } catch (error) {
      toast.error("Unable to load ad details", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(error, "this is an error");
    }
  }

  async function createAndUploadMedia() {
    const type = adDetails?.file.type?.split("/")[0]?.toUpperCase();

    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/create-media`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
          name: adDetails?.file?.name,
          type: type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const uploadLink =
        type == "VIDEO"
          ? "/snapchat/upload-media-video"
          : "/snapchat/upload-media-image";
      const fileFormData = new FormData();
      fileFormData.append("access_token", snapchatCredentials?.access_token);
      fileFormData.append("media_id", res?.data?.data?.media[0]?.media?.id);
      fileFormData.append("file_path", adDetails?.file);
      await axios.post(`${BACKEND_URL}${uploadLink}`, fileFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      });
      return res?.data?.data?.media[0]?.media?.id;
    } catch (error) {
      console.log(error);
      if (error.response?.data?.errors) {
        Object.values(error.response?.data?.errors)
          .flat()
          .map((err) =>
            toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
          );
      }
    }
  }

  async function createCreative() {
    try {
      const top_snap_media_id = adDetails?.topMedia
        ? adDetails?.topMedia?.id
        : await createAndUploadMedia();
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/create-creative`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
          name: adDetails?.name,
          shareable: `${adDetails?.shareable}`,
          profile_id: adDetails?.publicProfile?.value,
          brand_name: adDetails?.brandName,
          headline: adDetails?.headLine,
          type: "SNAP_AD",
          top_snap_media_id: top_snap_media_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      // getallC();
      return res?.data?.data?.creatives[0]?.creative?.id;
    } catch (error) {
      console.log(error, "this is an error");
      toast.error(error?.response?.data?.error, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      if (Object?.keys > 0) {
        Object?.values(error?.response?.data?.errors)
          .flat()
          .map((err) =>
            toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
          );
      }
    }
  }
  async function createAd() {
    setLoading(true);
    try {
      const creativeId = await createCreative();
      await axios.post(
        `${BACKEND_URL}/snapchat/create-ads`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
          name: adDetails?.name,
          ad_squad_id: isSingleAd?.adSet,
          creative_id: creativeId,
          type: "SNAP_AD",

          status: adDetails?.status ? "ACTIVE" : "PAUSED",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      toast.success("Successfully Created Ad", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      getAllAds();
      setIsSingleAd(false);
    } catch (error) {
      toast.error("Unable to create Ad", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(error, "this is an error");
      if (error.response?.data?.errors) {
        Object.values(error.response?.data?.errors)
          .flat()
          .map((err) =>
            toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
          );
      }
    } finally {
      setLoading(false);
    }
  }
  async function updateAd() {
    setLoading(true);
    try {
      await axios.post(
        `${BACKEND_URL}/snapchat/update-ads`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
          id: currentAdDetails?.id,
          status: adDetails?.status ? "ACTIVE" : "PAUSED",
          name: adDetails?.name,
          ad_squad_id: currentAdDetails?.ad_squad_id,
          creative_id: currentAdDetails?.creative_id,
          type: "SNAP_AD",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      toast.success("Successfully Updated Ad", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      getAllAds();
      setIsSingleAd(false);
    } catch (error) {
      toast.error("Unable to Update Ad", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(error, "this is an error");
      if (error.response?.data?.errors) {
        Object.values(error.response?.data?.errors)
          .flat()
          .map((err) =>
            toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
          );
      }
    } finally {
      setLoading(false);
    }
  }
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  // useEffect(() => {
  //   // Cleanup the URL when the component unmounts or when file changes
  //   return () => {
  //     if (adDetails?.file) {
  //       URL.revokeObjectURL(adDetails?.file);
  //     }
  //   };
  // }, [adDetails?.file]);
  return (
    <>
      <Modal
        // keepMounted
        open={isSingleAd}
        onClose={() => setIsSingleAd(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        sx={{ zIndex: 1300 }} // Adjust z-index as needed
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={`${
            isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
          } ${
            isClosing
              ? isLangRTL(i18n.language)
                ? "modal-close-left"
                : "modal-close-right"
              : ""
          }
          
        w-[100vw] h-[100vh] flex items-start justify-end `}
        >
          <button
            // onClick={handleCloseTimelineModel}
            onClick={handleClose}
            className={`${
              isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
            }
            bg-primary w-fit h-fit p-3 my-4 z-10`}
          >
            <MdClose
              size={18}
              color={"white"}
              className=" hover:border hover:border-white hover:rounded-full"
            />
          </button>
          {/* ${
              currentMode === "dark"
                ? "bg-[#000000] text-white"
                : "bg-[#F9F9FA] text-black"
            } */}

          <div
            style={style}
            className={`${getSummaryBgClass()}  ${
              isLangRTL(i18n.language)
                ? currentMode === "dark" && " border-primary border-r-2"
                : currentMode === "dark" && " border-primary border-l-2"
            } 
             pt-4 h-[100vh] w-[80vw] overflow-y-scroll border-primary px-7 flex flex-col relative
            `}
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                <h2 className="font-bold text-[16px] mb-5">Ad Details</h2>
                <div className="flex ">
                  <Box
                    sx={{
                      ...darkModeColors,
                      width: "70%",
                      "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                        {
                          right: isLangRTL(i18n.language)
                            ? "2.5rem"
                            : "inherit",
                          transformOrigin: isLangRTL(i18n.language)
                            ? "right"
                            : "left",
                        },
                      "& legend": {
                        textAlign: isLangRTL(i18n.language) ? "right" : "left",
                      },
                    }}
                  >
                    <div
                      className={`${getSummaryBgClass()} rounded-3xl px-8 py-4 shadow-md mb-5 flex flex-col gap-8 `}
                    >
                      <div className="flex flex-col">
                        <label htmlFor="" className="font-medium">
                          Name
                        </label>
                        <input
                          type="text"
                          className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
                          value={adDetails?.name}
                          placeholder="Name your ad"
                          onChange={(e) =>
                            setAdDetails((pre) => ({
                              ...pre,
                              name: e?.target?.value,
                            }))
                          }
                        />
                      </div>
                      <h3 className="text-[16px] medium ">
                        Select Public Profile
                      </h3>
                      <div className="w-full ">
                        <Select
                          // isLoading={adSetLoading}
                          id="public-Profiles"
                          options={publicProfiles}
                          value={adDetails?.publicProfile}
                          onChange={(e) => {
                            setAdDetails((pre) => ({
                              ...pre,
                              publicProfile: e,
                            }));
                          }}
                          placeholder={"Select Public Profile"}
                          // className={`mb-5`}
                          menuPortalTarget={document.body}
                          styles={selectStyles(currentMode, primaryColor)}
                          isDisabled={currentAdDetails}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="" className="font-medium">
                          Brand Name
                        </label>
                        <input
                          type="text"
                          className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
                          value={adDetails?.brandName}
                          placeholder="Replace Profile name"
                          disabled={currentAdDetails}
                          onChange={(e) =>
                            setAdDetails((pre) => ({
                              ...pre,
                              brandName: e?.target?.value,
                            }))
                          }
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="" className="font-medium">
                          Headline
                        </label>
                        <input
                          className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
                          type="text"
                          value={adDetails?.headLine}
                          placeholder="Headline to display in ad"
                          disabled={currentAdDetails}
                          onChange={(e) =>
                            setAdDetails((pre) => ({
                              ...pre,
                              headLine: e?.target?.value,
                            }))
                          }
                        />
                      </div>
                      <div className={"flex items-end"}>
                        <FormControlLabel
                          control={
                            <CustomSwitch
                              color="primary"
                              size="medium"
                              checked={adDetails?.shareable}
                              disabled={currentAdDetails}
                              onChange={(e) =>
                                setAdDetails((pre) => ({
                                  ...pre,
                                  shareable: e?.target?.checked,
                                }))
                              }
                            />
                          }
                          label="Shareable"
                          labelPlacement="top"
                          sx={{
                            margin: "0px",
                            marginTop: `10px`,
                          }}
                        />
                        <span className="pb-[10px] pl-1">
                          {adDetails?.shareable ? "Enabled" : "Disabled"}
                        </span>
                      </div>
                      <div>
                        <label>Topsnap Media</label>
                        <div className="mt-3 ">
                          {" "}
                          {/* <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .mp4, .mov"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const allowedTypes = [
                          "image/png",
                          "image/jpeg",
                          "video/mp4",
                          "video/quicktime",
                        ];

                        // Check if file exists and is an allowed type
                        if (file && allowedTypes.includes(file.type)) {
                          setAdDetails((prev) => ({
                            ...prev,
                            file,
                          }));
                        } else {
                          toast.error(
                            "Please upload a valid file (PNG, JPG, MP4, MOV).",
                            {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            }
                          );
                          e.target.value = null; // Reset input if the file is not valid
                        }
                      }}
                      style={{ display: "none" }}
                      id="top-snap-media"
                    /> */}
                          {adDetails?.media_url ? (
                            <div className="flex ">
                              <div>
                                {adDetails?.mediaType === "IMAGE" ? (
                                  <img
                                    src={adDetails?.media_url}
                                    className="w-[50px] h-[100px]"
                                    alt="creative media file"
                                  />
                                ) : (
                                  <video
                                    src={adDetails?.media_url}
                                    className="w-[50px] h-[100px]"
                                    alt="creative media file"
                                  ></video>
                                )}
                              </div>
                              <div className="flex flex-col px-4 items-center justify-center">
                                <span>{adDetails?.topSnapMediaId}</span>
                              </div>
                            </div>
                          ) : adDetails?.topMedia ? (
                            <>
                              <div className="flex gap-4">
                                <div>
                                  {adDetails?.topMedia?.type === "IMAGE" ? (
                                    <img
                                      src={adDetails?.topMedia?.download_link}
                                      className="w-[50px] h-[100px] rounded-2xl"
                                      alt="creative media file"
                                    />
                                  ) : (
                                    <video
                                      src={adDetails?.topMedia?.download_link}
                                      className="w-[50px] h-[100px]"
                                    ></video>
                                  )}
                                </div>
                                <div className="flex flex-col px-4 gap-4 justify-center">
                                  <span>{adDetails?.topMedia?.name}</span>
                                  <div
                                    onClick={() =>
                                      setAdDetails((pre) => ({
                                        ...pre,
                                        topMedia: null,
                                      }))
                                    }
                                    className="cursor-pointer"
                                  >
                                    <RiDeleteBin6Line size={20} />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : adDetails?.file ? (
                            <>
                              <div className="flex gap-4">
                                <div>
                                  {adDetails?.file.type
                                    ?.split("/")[0]
                                    ?.toUpperCase() === "IMAGE" ? (
                                    <img
                                      src={URL.createObjectURL(adDetails?.file)}
                                      className="w-[50px] h-[100px] rounded-2xl"
                                      alt="creative media file"
                                    />
                                  ) : (
                                    <video
                                      src={URL.createObjectURL(adDetails?.file)}
                                      className="w-[50px] h-[100px]"
                                    ></video>
                                  )}
                                </div>
                                <div className="flex flex-col px-4 gap-4 justify-center">
                                  <span>{adDetails?.file?.name}</span>
                                  <div
                                    onClick={() =>
                                      setAdDetails((pre) => ({
                                        ...pre,
                                        file: null,
                                      }))
                                    }
                                    className="cursor-pointer"
                                  >
                                    <RiDeleteBin6Line size={20} />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="flex gap-3">
                                <label
                                  className={` cursor-pointer ${
                                    currentMode === "dark"
                                      ? "bg-black text-white"
                                      : "text-[#444546] bg-white"
                                  } p-3 px-5 hover:border-[#F7D901]  rounded-[20px] border font-semibold `}
                                  // htmlFor="top-snap-media"
                                  onClick={() => setImagePickerModal(true)}
                                >
                                  Upload {adDetails?.file?.name}
                                </label>
                                <button
                                  onClick={() => setIsMediaFilesModal(true)}
                                  // className="p-3 px-5 hover:border-[#F7D901] text-[#444546] rounded-[20px] border font-semibold bg-white"
                                  className={` cursor-pointer ${
                                    currentMode === "dark"
                                      ? "bg-black text-white"
                                      : "text-[#444546] bg-white"
                                  } p-3 px-5 hover:border-[#F7D901]  rounded-[20px] border font-semibold `}
                                >
                                  Browse
                                </button>
                              </div>
                              <p className="pl-2 pt-2">Min 1080 x 1920</p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <h2 className="font-bold text-[16px] mb-5">Delivery</h2>
                    <div
                      className={`${getSummaryBgClass()} rounded-3xl px-8 py-4 shadow-md mb-9`}
                    >
                      <div className="flex items-end">
                        <FormControlLabel
                          value=""
                          control={
                            <CustomSwitch
                              color="primary"
                              size="medium"
                              checked={adDetails?.status}
                              onChange={(e) =>
                                setAdDetails((pre) => ({
                                  ...pre,
                                  status: e?.target?.checked,
                                }))
                              }
                            />
                          }
                          label="Status"
                          labelPlacement="top"
                          sx={{
                            margin: "0px",
                            marginTop: `10px`,
                          }}
                        />
                        <span className="pb-[10px] pl-1">
                          {adDetails?.status ? "ACTIVE" : "PAUSED"}
                        </span>
                      </div>

                      <div className="flex flex-col "></div>
                    </div>
                  </Box>
                  <div className="w-[30%] px-[40px] h-full flex items-center justify-center">
                    <div className="w-full h-[660px] bg-black rounded-2xl relative  pb-5">
                      <div className="flex items-center justify-between px-5 py-3">
                        <span className="text-white">11:04</span>{" "}
                        <div className="flex items-center gap-2">
                          <FaSignal color="white" size={16} />
                          <IoIosWifi color="white" size={16} />
                          <FaBatteryFull color="white" size={16} />
                        </div>
                      </div>
                      <div className=" w-full flex items-center justify-between px-5 py-3 absolute top-[40px]">
                        <span className="text-white px-1 rounded-md bg-gray-700 bg-opacity-50">
                          Ad
                        </span>{" "}
                        <span>
                          <BsThreeDotsVertical color="white" size={16} />
                        </span>
                      </div>
                      {adDetails?.media_url ? (
                        adDetails?.mediaType === "IMAGE" ? (
                          <img
                            src={adDetails?.media_url}
                            className="w-full h-[80%]"
                            alt="creative media file"
                          />
                        ) : (
                          <video
                            controls
                            src={adDetails?.media_url}
                            className="w-full h-[80%]"
                            alt="creative media file"
                          ></video>
                        )
                      ) : adDetails?.topMedia ? (
                        <>
                          {adDetails?.topMedia?.type === "IMAGE" ? (
                            <img
                              src={adDetails?.topMedia?.download_link}
                              className="w-full flex-1 rounded-2xl"
                              alt="creative media file"
                            />
                          ) : (
                            <video
                              controls
                              src={adDetails?.topMedia?.download_link}
                              className="w-full h-[80%]"
                            ></video>
                          )}
                        </>
                      ) : adDetails?.file ? (
                        <>
                          {adDetails?.file.type
                            ?.split("/")[0]
                            ?.toUpperCase() === "IMAGE" ? (
                            <img
                              src={URL.createObjectURL(adDetails?.file)}
                              className="w-full h-[80%] rounded-2xl"
                              alt="creative media file"
                            />
                          ) : (
                            <video
                              controls
                              src={URL.createObjectURL(adDetails?.file)}
                              className="w-full h-[80%]"
                            ></video>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="w-full h-[80%] bg-black rounded-2xl"></div>
                        </>
                      )}
                      <div className="px-5 flex justify-between items-center gap-5 mt-3 h-[15%]">
                        <div className="bg-white text-center text-black rounded-[20px] w-[85%] py-4 font-medium ">
                          Install Now
                        </div>
                        <div className="bg-gray-700 bg-opacity-50 rounded-2xl w-[15%] py-4 flex items-center justify-center">
                          <IoIosShareAlt size={16} color="white" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`flex justify-end py-5 ${getSummaryBgClass()} shadow-md px-4 mt-3`}
                  // style={{ width: "calc(100% - 40px)" }}
                >
                  {!currentAdDetails && (
                    <button
                      onClick={() => createAd()}
                      className={`p-3 px-5 hover:border-[#F7D901] rounded-[20px] disabled:cursor-not-allowed border font-semibold ${
                        currentMode === "dark"
                          ? "bg-black text-white"
                          : "bg-white"
                      }`}
                      // disabled={
                      //   !adDetails?.name ||
                      //   !adDetails?.headLine ||
                      //   (adDetails?.topMedia
                      //     ? !adDetails?.file
                      //     : !adDetails?.topMedia) ||
                      //   !adDetails?.publicProfile
                      // }
                      disabled={
                        !adDetails?.name || // true if name is not available
                        !adDetails?.headLine || // true if headLine is not available
                        (!adDetails?.topMedia // Check if topMedia is available
                          ? !adDetails?.file // If topMedia is available, check if file is not available
                          : !adDetails?.topMedia) || // If topMedia is not available, this will always be false
                        !adDetails?.publicProfile // true if publicProfile is not available
                      }
                    >
                      Next
                    </button>
                  )}

                  {currentAdDetails && (
                    <button
                      style={
                        isChangesOccured
                          ? { backgroundColor: "black", color: "white" }
                          : currentMode === "dark"
                          ? { backgroundColor: "#282b30", color: "white" }
                          : stripeStyle
                      }
                      className={`p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold ${
                        currentMode === "dark"
                          ? "bg-black text-white"
                          : "bg-white"
                      } `}
                      disabled={!isChangesOccured}
                      onClick={() => updateAd()}
                    >
                      Save Changes
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <ImagePicker
        imagePickerModal={imagePickerModal}
        setImagePickerModal={setImagePickerModal}
        setCreativeDetails={setAdDetails}
      />
      <MediaFiles
        isMediaFilesModal={isMediaFilesModal}
        setIsMediaFilesModal={setIsMediaFilesModal}
        setCreativeDetails={setAdDetails}
      />
    </>
  );
};

export default SingleAd;
