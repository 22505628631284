import React, { useState } from "react";
import {
  Box,
  FormControlLabel,
  FormLabel,
  Menu,
  Radio,
  RadioGroup,
} from "@mui/material";
import { selectStyles } from "../_elements/SelectStyles";
import { useStateContext } from "../../context/ContextProvider";
import Select from "react-select";
import { FormControl } from "@mui/base";
import { IoSearch } from "react-icons/io5";
import DetailedTargeting from "./DetailTargeting";
import { RxCross2 } from "react-icons/rx";

const locationInclude = [
  {
    label: "Include",
    value: 1,
  },
  {
    label: "Exlude",
    value: 2,
  },
];
const Ages = [
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  "65+",
].map((age) => ({
  label: `${age}`,
  value: age,
}));
const Audiences = () => {
  const { currentMode, primaryColor, themeBgImg } = useStateContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElTargeting, setAnchorElTargeting] = useState(null);

  const [newLocation, setNewLocation] = useState({ name: "", radius: "2 km" });
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  return (
    <>
      <Box>
        <label htmlFor="" className="text-[16px] font-semibold mb-3">
          Advantage+ audience
        </label>
        <p>
          Our ad technology automatically finds your audience. If you share an
          audience suggestion, we'll prioritise audiences matching this profile
          before searching more widely..
        </p>

        <div className="flex items-center justify-between mb-3">
          <label
            htmlFor=""
            className="text-[14px] font-semibold mb-3 flex gap-2 items-center"
          >
            Custom Audiences
          </label>
          <button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            className="px-3 py-2 border border-gray-200 rounded-lg flex gap-3 items-center"
          >
            Create New
          </button>
        </div>
        <div className="w-full ">
          <Select
            // isLoading={adSetLoading}
            id="goals"
            options={[]}
            // value={adDetails?.publicProfile}
            // onChange={(e) => {
            //   setAdDetails((pre) => ({
            //     ...pre,
            //     publicProfile: e,
            //   }));
            // }}
            placeholder={"Search existing audiences"}
            // className={`mb-5`}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />
        </div>
        <label
          htmlFor=""
          className="text-[14px] font-semibold mb-3 flex gap-2 items-center"
        >
          Age
        </label>
        <div className="grid grid-cols-2 w-[250px] gap-3">
          <Select
            // isLoading={adSetLoading}
            id="min Age"
            options={Ages}
            // value={adDetails?.publicProfile}
            // onChange={(e) => {
            //   setAdDetails((pre) => ({
            //     ...pre,
            //     publicProfile: e,
            //   }));
            // }}
            placeholder={"Min"}
            // className={`mb-5`}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />
          <Select
            // isLoading={adSetLoading}
            id="max-ages"
            options={Ages}
            // value={adDetails?.publicProfile}
            // onChange={(e) => {
            //   setAdDetails((pre) => ({
            //     ...pre,
            //     publicProfile: e,
            //   }));
            // }}
            placeholder={"Max"}
            // className={`mb-5`}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />
        </div>
        {/* <label
          htmlFor=""
          className="text-[14px] font-semibold mb-3 flex gap-2 items-center"
        >
          Gender
        </label> */}
        <FormControl component="fieldset">
          {/* <FormLabel component="legend">Gender</FormLabel> */}
          <label
            htmlFor=""
            className="text-[14px] font-semibold mb-3 flex gap-2 items-center"
          >
            Gender
          </label>
          <RadioGroup
            name="gender"
            // value={gender}
            // onChange={handleGenderChange}
            row // Optional: Makes it horizontal
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel value="men" control={<Radio />} label="Men" />
            <FormControlLabel value="women" control={<Radio />} label="Women" />
          </RadioGroup>
        </FormControl>

        <label
          htmlFor=""
          className="text-[14px] font-semibold mb-3 flex gap-2 items-center"
        >
          Detailed targeting
        </label>
        <p className="text-gray-500 font-medium mb-3">
          Include people who match
        </p>

        <div
          // className="bg-[#F2F2F2] rounded-lg py-3 px-4"
          className={` ${
            currentMode === "dark" ? "bg-gray-700" : "bg-[#F2F2F2]"
          } rounded-lg py-3 px-4`}
        >
          <div
            //  className="bg-white flex justify-between items-center px-3 rounded-lg py-3 group"
            className={` ${
              currentMode === "dark" ? "bg-black" : "bg-white"
            } flex justify-between items-center px-3 rounded-lg py-3 my-3 group`}
          >
            <div className="flex gap-4 items-center">At high school</div>
            <span className="group-hover:block hidden cursor-pointer">
              <RxCross2 />
            </span>
          </div>
          <div
            // className="bg-white flex justify-between items-center px-3 rounded-lg py-3 group"
            className={` ${
              currentMode === "dark" ? "bg-black" : "bg-white"
            } flex justify-between items-center px-3 rounded-lg py-3 my-3 group`}
          >
            <div className="flex gap-4 items-center">At high University</div>
            <span className="group-hover:block hidden cursor-pointer">
              <RxCross2 />
            </span>
          </div>
        </div>
        <div className="flex items-center gap-4 border-b py-5 px-7 flex-1 mb-5">
          <IoSearch size={16} />

          <input
            type="text"
            className={`focus:outline-none border-none flex-1 ${
              currentMode === "dark" ? "bg-dark" : "bg-white"
            }`}
            placeholder="Add demographics,interests, or behaviours"
            // onChange={(e) => setSearchTerm(e?.target?.value)}
            // value={searchTerm}
          />
          <button
            onClick={(event) => {
              setAnchorElTargeting(event.currentTarget?.parentElement);
              console.log(
                event.currentTarget?.parentElement,
                "parent of browse"
              );
            }}
          >
            Browse
          </button>
        </div>
        <button
          onClick={(event) => setAnchorEl(event.currentTarget)}
          className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 ${
            currentMode == "dark" ? "bg-black text-white" : "bg-white"
          } `}
        >
          Narrow audience
        </button>
        <div className="flex items-center justify-between gap-3 w-full pt-3 border-t-[2px]">
          <button
            // onClick={() => setCreateCampaignModal(false)}
            className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium ${
              currentMode == "dark" ? "bg-black text-white" : "bg-white"
            } `}
          >
            Save Audience
          </button>
          <button className="text-[#0A78BE]">
            Switch to original audience options
          </button>
        </div>
        {/* <div className="bg-[#F2F2F2] rounded-lg py-3 px-4">
        <label htmlFor="" className="text-[14px] mb-3">
          Pakistan
        </label>
        <div className="bg-white flex justify-between items-center px-3 rounded-lg py-3 group">
          <div className="flex gap-4 items-center">
            <MdLocationOn color="green" size={16} />
            Charsadda Sherpao
          </div>
          <span className="group-hover:block hidden cursor-pointer">
            <RxCross2 />
          </span>
        </div>
      </div>
      <div className="flex items-center gap-3 mt-3">
        <div className="w-[150px]">
          <Select
            // isLoading={adSetLoading}
            id="include-exlude"
            options={locationInclude}
            // value={adDetails?.publicProfile}
            // onChange={(e) => {
            //   setAdDetails((pre) => ({
            //     ...pre,
            //     publicProfile: e,
            //   }));
            // }}
            placeholder={""}
            className={`w-full mb-0`}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />
        </div>

        <div className="flex items-center gap-4 border-b py-5 px-7 flex-1 mb-5">
          <IoSearch size={16} />

          <input
            type="text"
            className={`focus:outline-none border-none flex-1 ${
              currentMode === "dark" ? "bg-dark" : "bg-white"
            }`}
            placeholder="Search Locations"
            // onChange={(e) => setSearchTerm(e?.target?.value)}
            // value={searchTerm}
          />
        </div>
      </div> */}
        {/* Map */}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiList-root": {
            padding: "0px !important", // Modify border radius
          },
          padding: "0px",
        }}
      >
        <div className={` ${getSummaryBgClass()} py-4`}>
          <div className="">
            <ul className="px-1">
              <li
                className={`w-full hover:bg-gray-200 py-3 ${
                  currentMode === "dark" ? "hover:text-black" : ""
                } px-2 rounded-lg cursor-pointer flex flex-col justify-between gap-4`}
              >
                <span className="text-[14px]">Upcoming entries</span>
                <p>
                  Reach people who have already interacted with your business.
                </p>
              </li>
              <li
                className={`w-full hover:bg-gray-200 py-3 ${
                  currentMode === "dark" ? "hover:text-black" : ""
                } px-2 rounded-lg cursor-pointer flex flex-col justify-between gap-4`}
              >
                <span className="text-[14px]">Lookalike Audience</span>
                <p>
                  Reach new people on Meta technologies who are similar to your
                  most valuable audiences.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </Menu>
      <DetailedTargeting
        anchorEl={anchorElTargeting}
        setAnchorEl={setAnchorElTargeting}
      />
    </>
  );
};

export default Audiences;
