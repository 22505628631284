import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { IoSearchOutline } from "react-icons/io5";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { useStateContext } from "../../context/ContextProvider";
import { IoMdArrowUp, IoMdArrowDown } from "react-icons/io";

export default function TimeScheduleView({ anchorEl, setAnchorEl }) {
  const open = Boolean(anchorEl);
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    setSnapchatCredentials,
  } = useStateContext();

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? "bg-dark text-white"
      : "bg-white text-black";
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiList-root": {
            padding: "0px !important", // Modify border radius
          },
          padding: "0px",
        }}
      >
        <div className={` ${getSummaryBgClass()} py-4`}>
          {/* <div className="flex items-center gap-3 px-4 pt-3">
            <IoSearchOutline size={16} />
            <input
              type="text"
              className={`focus:outline-none border-none ${getSummaryBgClass()}`}
              placeholder={`Filter ...`}
            />
          </div> */}
          <div className="">
            <h3 className="text-[14px] font-semibold mb-3 px-3">Filter</h3>
            <ul className="px-1">
              <li
                className={`w-full hover:bg-gray-200 py-3 ${
                  currentMode === "dark" ? "hover:text-black" : ""
                } px-2 rounded-lg cursor-pointer flex justify-between gap-4`}
              >
                <span>Upcoming entries</span>
                <div>
                  <span>2</span>
                  <span>1</span>
                </div>
              </li>
              <li
                className={`w-full hover:bg-gray-200 py-3 ${
                  currentMode === "dark" ? "hover:text-black" : ""
                } px-2 rounded-lg cursor-pointer flex justify-between gap-4`}
              >
                <span>Completed entries</span>
                <div>
                  <span>2</span>
                  <span>1</span>
                </div>
              </li>
              <li
                className={`w-full hover:bg-gray-200 py-3 ${
                  currentMode === "dark" ? "hover:text-black" : ""
                } px-2 rounded-lg cursor-pointer flex justify-between gap-4`}
              >
                <span>All entries</span>
                <div>
                  <span>2</span>
                  <span>1</span>
                </div>
              </li>
            </ul>
            <h3 className="text-[14px] font-semibold mb-3 px-3">Sort</h3>
            <ul className="px-1">
              <li
                className={`w-full hover:bg-gray-200 py-3 px-2 rounded-lg cursor-pointer flex items-center gap-2 ${
                  currentMode === "dark" ? "hover:text-black" : ""
                }`}
              >
                <span>
                  <IoMdArrowUp size={16} />
                </span>
                <span>Sort by newest to oldest</span>
              </li>
              <li
                className={`w-full hover:bg-gray-200 py-3 px-2 rounded-lg cursor-pointer flex items-center gap-2 ${
                  currentMode === "dark" ? "hover:text-black" : ""
                }`}
              >
                <span>
                  <IoMdArrowDown size={16} />
                </span>
                <span>Sort by oldest to newest</span>
              </li>
            </ul>
          </div>
        </div>
      </Menu>
    </div>
  );
}
