import React, { useEffect, useState } from "react";
import { BsMegaphone } from "react-icons/bs";
import { MdAdd, MdFilterList } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { PiGridFourLight } from "react-icons/pi";
import { LuRectangleVertical } from "react-icons/lu";
import CompaignsTable from "./CompaignsTable";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import CreateCampaignModal from "./CreateCampaign";
import SingleCampaign from "./SingleCampaign";
import AdSetsTable from "./adSetTable";
import SingleAdSet from "./SingleAdSet";
import CreateAdSetModal from "./createAdSet";
import AdsTable from "./AdsTable";
import SingleAd from "./singleAd";
import CreateAdModal from "./createAd";

const token = localStorage?.getItem("auth-token");
const oldObjectives = {
  awareness: [
    {
      label: "Awareness",
      banner:
        "https://release-manager.sc-cdn.net/218e1a0c4b913ae4d17c6404d31ae915.svg",
      content: {
        para: "Reach Snapchatters and create interest in your brand",
      },
      identifier: "BRAND_AWARENESS",
    },
    {
      label: "Promote Places",
      banner: "/assets/promote_places.png",
      content: {
        para: "Promote your places to Snapchatters",
      },
      identifier: "PROMOTE_PLACES",
    },
  ],
  consideration: [
    {
      label: "App Installs",
      banner: "/assets/app_installs.png",
      content: {
        para: "Send Snapchatters to the app store to download your app",
      },
      identifier: "APP_INSTALL",
    },
    {
      label: "Drive Traffic to Website",

      banner: "/assets/website_traffic.png",
      content: {
        para: "Send Snapchatters directly to your website",
      },
      identifier: "WEB_VIEW",
    },
    {
      label: "Drive Traffic to App",
      banner: "/assets/app_traffic.png",
      content: {
        para: "Send Snapchatters to your app or a third-party app",
      },
      identifier: "APP_REENGAGEMENT",
    },
    {
      label: "Engagement",

      banner: "/assets/engagement.png",
      content: {
        para: "Get more Snapchatters to engage with your ad",
      },
      identifier: "ENGAGEMENT",
    },
    {
      label: "Video Views",

      banner: "/assets/video_views.png",
      content: {
        para: "Promote your brand or product to Snapchatters through video. Learn more.",
      },
      identifier: "VIDEO_VIEW",
    },
    {
      label: "Lead Generation",

      banner: "/assets/leads.png",
      content: {
        para: "Generate leads for your business",
      },
      identifier: "LEAD_GENERATION",
    },
  ],
  conversions: [
    {
      label: "App Conversions",

      banner: "/assets/app_promotion.png",
      content: {
        para: "Drive specific actions within your app",
      },
      identifier: "APP_CONVERSION",
    },
    {
      label: "Website Conversions",

      banner: "/assets/app_promotion.png",
      content: {
        para: "Drive specific actions on your website",
      },
      identifier: "WEB_CONVERSION",
    },
  ],
};

const Compaigns = () => {
  const {
    currentMode,
    themeBgImg,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
  } = useStateContext();
  const [currentTable, setCurrentTable] = useState("campaigns");
  const [isCampaignModal, setIsCampaignModal] = useState(false);
  const [isSingleCampaign, setIsSingleCampaign] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [isSingleAdSet, setIsSingleAdSet] = useState(false);
  const [isSingleAd, setIsSingleAd] = useState(false);
  const [createAdSetModal, setCreateAdSetModal] = useState(false);
  const [adSetDetails, setAdSetDetails] = useState(null);
  const [adDetails, setAdDetails] = useState(null);
  const [compaigns, setComapaigns] = useState([]);
  const [adSets, setAdSets] = useState([]);
  const [ads, setAds] = useState([]);
  const [createAdModal, setCreateAdModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState({
    campaigns: [],
    adSets: [],
  });
  const [loading, setLoading] = useState(false);
  async function getAllCompaigns() {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/all/campaigns`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setComapaigns(res?.data?.data?.campaigns?.map((item) => item?.campaign));
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  }

  async function getAllAdSets() {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/getall-adsquad-under-adaccount`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setAdSets(res?.data?.data?.adsquads?.map((item) => item?.adsquad));
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  }

  async function getAllAds() {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/ads-under-adaccount`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setAds(res?.data?.data?.ads?.map((item) => item?.ad));
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  }
  async function getAllAdSetsUnderCampaign(id) {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/getall-adsquad-under-campaign`,
        {
          access_token: snapchatCredentials?.access_token,
          campaign_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      return res?.data?.data?.adsquads?.map((item) => item?.adsquad);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  }
  async function getAllAdsUnderCampaign(id) {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/ads-under-campaign`,
        {
          access_token: snapchatCredentials?.access_token,
          campaign_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      return res?.data?.data?.ads?.map((item) => item?.ad);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  }
  async function getAllAdsUnderAdSet(id) {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/ads-under-adsquad`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_squad_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      return res?.data?.data?.ads?.map((item) => item?.ad);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (snapchatCredentials?.access_token) {
      getAllCompaigns();
    }
  }, [snapchatCredentials]);

  useEffect(() => {
    if (currentTable === "adSets" && selectedRows?.campaigns?.length > 0) {
      fetchAdSetsOfDifferentCampaings();
    } else if (snapchatCredentials?.access_token && currentTable === "adSets") {
      getAllAdSets();
    } else if (currentTable === "ads" && selectedRows?.adSets?.length > 0) {
      fetchAdsOfDifferentAdSets();
    } else if (currentTable === "ads" && selectedRows?.campaigns?.length > 0) {
      fetchAdsOfDifferentCampaings();
    } else if (snapchatCredentials?.access_token && currentTable === "ads") {
      getAllAds();
    }
  }, [currentTable]);

  async function fetchAdSetsOfDifferentCampaings() {
    setLoading(true);
    try {
      const adSetsPromises = selectedRows?.campaigns?.map(async (id) => {
        try {
          return await getAllAdSetsUnderCampaign(id);
        } catch (error) {
          console.log(error);
          return [];
        }
      });

      const adSets = await Promise.all(adSetsPromises);
      setAdSets(adSets.flat()); // Flatten the array of arrays
    } catch (error) {
      console.log(error, "this is an error");
    } finally {
      setLoading(false);
    }
  }
  async function fetchAdsOfDifferentCampaings() {
    setLoading(true);
    try {
      const adsPromises = selectedRows?.campaigns?.map(async (id) => {
        try {
          return await getAllAdsUnderCampaign(id);
        } catch (error) {
          console.log(error);
          return [];
        }
      });

      const ads = await Promise.all(adsPromises);
      setAds(ads.flat()); // Flatten the array of arrays
    } catch (error) {
      console.log(error, "this is an error");
    } finally {
      setLoading(false);
    }
  }
  async function fetchAdsOfDifferentAdSets() {
    setLoading(true);
    try {
      const adsPromises = selectedRows?.adSets?.map(async (id) => {
        try {
          return await getAllAdsUnderAdSet(id);
        } catch (error) {
          console.log(error);
          return [];
        }
      });

      const ads = await Promise.all(adsPromises);
      setAds(ads.flat()); // Flatten the array of arrays
    } catch (error) {
      console.log(error, "this is an error");
    } finally {
      setLoading(false);
    }
  }
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  return (
    <>
      <div className={`px-6 ${getSummaryBgClass()}`}>
        {/* <div className="flex gap-1 py-9">
       
          <div
            className={`flex items-center  gap-8 py-6  px-5  ${getSummaryBgClass()} flex-1 rounded-md shadow-md`}
          >
            <div>
              <FiSearch size={16} />
            </div>
            <input
              type="text"
              className={`focus:outline-none flex-1 ${getSummaryBgClass()}`}
              placeholder="Search name or ID"
            />
          </div>
        </div> */}
        <div className="shadow-lg">
          <div
            className={`flex  justify-between  px-5 border-b  border-b-gray-400 ${getSummaryBgClass()}`}
          >
            <div className="flex gap-8 items-center">
              <div
                onClick={() => setCurrentTable("campaigns")}
                className={`flex gap-3 items-center h-full py-7 cursor-pointer border-b-[3px]  ${
                  currentTable === "campaigns"
                    ? "border-b-[#F7D901]"
                    : "border-b-transparent"
                }`}
              >
                <BsMegaphone />
                <span className="font-medium text-[14px]">Campaigns</span>
                {selectedRows?.campaigns?.length > 0 && (
                  <div className="rounded-[20px] p-3 border">
                    {selectedRows?.campaigns?.length} Selected
                  </div>
                )}
              </div>
              <div
                onClick={() => setCurrentTable("adSets")}
                // className="flex gap-3 items-center h-full py-7 cursor-pointer hover:border-b-[3px] border-b-[#F7D901]"
                className={`flex gap-3 items-center h-full py-7 cursor-pointer border-b-[3px]   ${
                  currentTable === "adSets"
                    ? "border-b-[#F7D901]"
                    : "border-b-transparent"
                }`}
              >
                <PiGridFourLight />
                <div>
                  <span className="font-medium text-[14px]">Ad set</span>
                  {/* <p>for 1 compaign</p> */}
                  {selectedRows?.campaigns?.length > 0 && (
                    <div className="">
                      for {selectedRows?.campaigns?.length} Campaigns
                    </div>
                  )}
                </div>
                {selectedRows?.adSets?.length > 0 && (
                  <div className="rounded-[20px] p-3 border">
                    {selectedRows?.adSets?.length} Selected
                  </div>
                )}
              </div>
              <div
                onClick={() => setCurrentTable("ads")}
                // className="flex gap-3 items-center h-full py-7 cursor-pointer hover:border-b-[3px] border-b-[#F7D901]"
                className={`flex gap-3 items-center h-full py-7 cursor-pointer border-b-[3px]   ${
                  currentTable === "ads"
                    ? "border-b-[#F7D901]"
                    : "border-b-transparent"
                }`}
              >
                <LuRectangleVertical />
                <div>
                  <span className="font-medium text-[14px]"> Ads</span>
                  {selectedRows?.adSets?.length > 0 ? (
                    <div className="">
                      for {selectedRows?.adSets?.length} ad set
                    </div>
                  ) : (
                    selectedRows?.campaigns?.length > 0 && (
                      <div className="">
                        for {selectedRows?.campaigns?.length} Campaigns
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="flex gap-3 items-center">
              {currentTable == "campaigns" && (
                <>
                  <button
                    onClick={() => setIsCampaignModal(true)}
                    className="flex gap-1 items-center rounded-2xl bg-black text-white p-3"
                  >
                    <MdAdd size={16} />
                    Create Campaign
                  </button>
                </>
              )}
              {currentTable == "adSets" && (
                <>
                  {/* setCreateAdSetModal(true) */}
                  <button
                    onClick={() => {
                      setCreateAdSetModal(true);
                      setAdDetails(null);
                    }}
                    className="flex gap-1 items-center rounded-2xl bg-black text-white p-3"
                  >
                    <MdAdd size={16} />
                    Create Ad Set
                  </button>
                </>
              )}
              {currentTable == "ads" && (
                <>
                  {/* <button className="rounded-2xl bg-[#E1E3E5] p-3">
                    Run creative split test
                  </button> */}
                  <button
                    // onClick={() => setIsSingleAd(true)}
                    onClick={() => setCreateAdModal(true)}
                    className="flex gap-1 items-center rounded-2xl bg-black text-white p-3"
                  >
                    <MdAdd size={16} />
                    Create Ad
                  </button>
                </>
              )}
            </div>
          </div>
          {currentTable === "campaigns" && (
            <CompaignsTable
              setIsSingleCampaign={setIsSingleCampaign}
              setCampaignDetails={setCampaignDetails}
              oldObjectives={oldObjectives}
              getAllCompaigns={getAllCompaigns}
              compaigns={compaigns}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              loading={loading}
            />
          )}
          {currentTable === "adSets" && (
            <AdSetsTable
              setAdSetDetails={setAdSetDetails}
              setIsSingleAdSet={setIsSingleAdSet}
              getAllAdSets={getAllAdSets}
              adSets={adSets}
              setAdSets={setAdSets}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              loading={loading}
            />
          )}
          {currentTable === "ads" && (
            <AdsTable
              setAdDetails={setAdDetails}
              setIsSingleAd={setIsSingleAd}
              getAllAds={getAllAds}
              ads={ads}
              setAds={setAds}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              loading={loading}
            />
          )}
        </div>
      </div>
      <CreateCampaignModal
        setCreateCampaignModal={setIsCampaignModal}
        createCampaignModal={isCampaignModal}
        setIsSingleCampaign={setIsSingleCampaign}
        oldObjectives={oldObjectives}
        setCurrentCampaignDetails={setCampaignDetails}
      />
      <SingleCampaign
        isSingleCampaign={isSingleCampaign}
        setIsSingleCampaign={setIsSingleCampaign}
        setCreateCampaignModal={setIsCampaignModal}
        currentCampaignDetails={campaignDetails}
        setCurrentCampaignDetails={setCampaignDetails}
        getAllCompaigns={getAllCompaigns}
      />
      <SingleAdSet
        isSingleAdSet={isSingleAdSet}
        setIsSingleAdSet={setIsSingleAdSet}
        currentAdSetDetails={adSetDetails}
        getAllAdSets={getAllAdSets}
      />
      <CreateAdSetModal
        setCreateAdSetModal={setCreateAdSetModal}
        createAdSetModal={createAdSetModal}
        setIsSingleAdSet={setIsSingleAdSet}
        setAdSetDetails={setAdSetDetails}
      />

      <SingleAd
        isSingleAd={isSingleAd}
        setIsSingleAd={setIsSingleAd}
        currentAdDetails={adDetails}
        getAllAds={getAllAds}
      />
      <CreateAdModal
        setCreateAdModal={setCreateAdModal}
        createAdModal={createAdModal}
        setIsSingleAd={setIsSingleAd}
        setAdDetails={setAdDetails}
      />
    </>
  );
};

export default Compaigns;
