import React, { useEffect, useState } from "react";
import { Backdrop, Modal } from "@mui/material";
import Select from "react-select";
import { useStateContext } from "../../context/ContextProvider";
import { MdOutlineCampaign } from "react-icons/md";
import { BsCart4 } from "react-icons/bs";
import { PiCursorFill } from "react-icons/pi";
import { IoFilterOutline } from "react-icons/io5";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { selectStyles } from "../_elements/SelectStyles.jsx";
import { buying_type } from "../_elements/SelectOptions.js";
const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};

const objectives = [
  {
    label: "Awareness",
    icon: <MdOutlineCampaign size={16} />,
    banner:
      "https://release-manager.sc-cdn.net/218e1a0c4b913ae4d17c6404d31ae915.svg",
    value: "OUTCOME_AWARENESS",
    type: "reserved",
    content: {
      para: "Show your ads to people who are most likely to remember them.",
      Outcomes: {
        label: "good for Awareness & Engagement objective.",
        points: [
          "Reach",
          "Brand Awarenes",
          "Video Views",
          "Store location awareness",
          "Ad Engagement",
          "Calls & Texts",
          "Place Promotion",
        ],
      },
      AdFormats: {
        label: " available for Awareness & Engagement objective.",
        points: [
          "Signle Image or Video",
          "Story Ad",
          "Collection Ad",
          "AR Ads",
          "Commercial",
        ],
      },
    },
  },
  {
    label: "Traffic",
    icon: <PiCursorFill size={16} />,
    banner: "/assets/website_traffic.png",
    value: "OUTCOME_TRAFFIC",
    content: {
      para: "Send people to a destination, such as your website, app, Instagram profile or Facebook event",
      Outcomes: {
        label: "good for Traffic objective.",
        points: [
          "Link Clicks",
          "Landing Page Views",
          "Instagram profile visits",
          "Messenger, Instagram and WhatsApp",
          "Calls",
        ],
      },
    },
  },
  {
    label: "Engagement",
    icon: <PiCursorFill size={16} />,
    banner: "/assets/engagement.png",
    value: "OUTCOME_ENGAGEMENT",
    type: "reserved",
    content: {
      para: "Get more messages, purchases through messaging, video views, post engagement, Page likes or event responses.",
      Outcomes: {
        points: [
          "Messenger, Instagram and WhatsApp",
          "Video views",
          "Post engagement",
          "Conversions",
          "Calls",
        ],
      },
    },
  },
  {
    label: "Leads",
    icon: <IoFilterOutline size={16} />,
    banner: "/assets/leads.png",
    value: "OUTCOME_LEADS",
    content: {
      para: "Collect leads for your business or brand.",
      Outcomes: {
        points: [
          "Instant Forms",
          "Messenger and Instagram",
          "Conversions",
          "Calls",
        ],
      },
    },
  },
  {
    label: "App promotion",
    icon: <HiOutlineDevicePhoneMobile size={16} />,
    banner: "/assets/app_promotion.png",
    value: "OUTCOME_APP_PROMOTION",
    content: {
      para: "Find new people to install your app and continue using it",
      Outcomes: {
        points: ["App installs", "App events"],
      },
    },
  },
  {
    label: "Sales",
    icon: <BsCart4 size={16} />,
    banner: "/assets/sales.png",
    value: "OUTCOME_SALES",
    content: {
      para: "Find people who are likely to purchase your product or service",
      Outcomes: {
        points: [
          "Conversions",
          "Catalogue sales",
          "Messenger, Instagram and WhatsApp",
          "Calls",
        ],
      },
    },
  },
];

const CreateCampaignModal = ({
  setCreateCampaignModal,
  createCampaignModal,
  setIsSingleCampaign,
  oldObjectives,
  setCurrentCampaignDetails,
  campData,
  setCampData,
}) => {
  const { darkModeColors, currentMode, User, BACKEND_URL, t, primaryColor } =
    useStateContext();
  const [filteredObjectives, setFilteredObjectives] = useState(
    objectives || []
  );

  const [selectedDetails, setSelectedDetails] = useState(null);
  const [hoveredObjective, setHoveredObjective] = useState(null);

  const currentDetails = hoveredObjective || selectedDetails;

  console.log(selectedDetails, "selected details");

  useEffect(() => {
    if (campData?.buying_type?.toLowerCase() === "reserved") {
      setFilteredObjectives(
        objectives?.filter((obj) => obj.type === "reserved")
      );
    } else {
      setFilteredObjectives(objectives);
    }
  }, [campData?.buying_type, objectives]);

  return (
    <Modal
      // keepMounted
      open={createCampaignModal}
      onClose={() => setCreateCampaignModal(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        zIndex: 1400,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[47%] h-[720px]  overflow-y-auto ${
          // currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          currentMode === "dark"
            ? "bg-dark-neu text-white"
            : "bg-white text-black"
        } absolute top-1/2 left-1/2 p-5 rounded-md flex flex-col items-start`}
      >
        <h3 className="text-[20px] font-semibold">Choose Objective</h3>
        <h3 className="text-[14px] font-semibold my-3">Buying Type</h3>
        <div className="w-full ">
          <Select
            // isLoading={adSetLoading}
            id="public-Profiles"
            options={buying_type(t)}
            value={buying_type(t)?.find(
              (type) => type.value == campData?.buying_type
            )}
            onChange={(e) => {
              if (e.value?.toLowerCase() == "reserved") {
                setCampData((pre) => ({
                  ...pre,
                  objective: "",
                }));
              }
              setCampData((pre) => ({
                ...pre,
                buying_type: e.value,
              }));
            }}
            placeholder={"Buying Type"}
            // className={`mb-5`}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />
        </div>
        <p className="py-3 font-semibold text-[14px]">Objective</p>

        <div className="flex w-full flex-1">
          <ul className="pl-4 pt-4 w-1/2 px-4">
            {filteredObjectives?.map((objective) => {
              return (
                <li
                  className={`flex gap-4 items-center mb-8 group cursor-pointer px-2 py-2 rounded-lg  ${
                    selectedDetails?.label === objective?.label
                      ? "bg-[#C3DCF5]"
                      : ""
                  }`}
                  onMouseEnter={() => setHoveredObjective(objective)}
                  onMouseLeave={() => setHoveredObjective(null)}
                  onClick={() => {
                    setCampData({
                      ...campData,
                      objective: objective?.value,
                    });
                    setSelectedDetails(objective);
                  }}
                >
                  {" "}
                  <div
                    className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
                      selectedDetails?.label === objective?.label
                        ? "border-[5px]"
                        : "group-hover:border-[3px]"
                    }  `}
                  ></div>
                  <div
                    className={`rounded-xl ${
                      currentMode === "dark"
                        ? "text-black"
                        : selectedDetails?.label === objective?.label
                        ? "text-white"
                        : "text-black"
                    } ${
                      selectedDetails?.label === objective?.label
                        ? `bg-[#0A78BE] `
                        : "bg-[#EDEEEF]"
                    }  p-3 `}
                  >
                    {objective.icon}
                  </div>
                  <span
                    className={`text-[14px] ${
                      currentMode === "dark"
                        ? selectedDetails?.label === objective?.label
                          ? "text-black"
                          : "text-white"
                        : "text-black"
                    }`}
                  >
                    {objective?.label}
                  </span>
                </li>
              );
            })}
          </ul>
          <div className="w-1/2">
            {currentDetails ? (
              <>
                <img
                  src={currentDetails?.banner}
                  alt={currentDetails?.banner}
                  className="w-[100px] object-cover mb-3"
                />
                <h4 className="text-[16px] font-semibold">
                  {currentDetails?.label}
                </h4>

                <p className="text-[14px]  mb-5">
                  {currentDetails?.content?.para}
                </p>
                <div className="flex items-center gap-3 mb-3">
                  <h4 className="text-[16px] font-semibold">Good for:</h4>
                  {/* <p>{currentDetails?.content?.Outcomes?.label}</p> */}
                </div>
                <div className="flex gap-3 flex-col flex-wrap items-start mb-3">
                  {currentDetails?.content?.Outcomes?.points?.map((outcome) => (
                    <button
                      className={`rounded-xl bg-[#EDEEEF] p-1 px-2 text-black`}
                    >
                      {outcome}
                    </button>
                  ))}
                </div>
              </>
            ) : (
              <>
                <img src="/assets/general.png" className="w-[100px] " />
                <p>
                  Your campaign objective is the business goal you hope to
                  achieve by running your ads. Hover over each one for more
                  information.
                </p>
              </>
            )}
          </div>
        </div>

        <div className="flex items-center justify-end gap-3 w-full pt-3 border-t-[2px]">
          <button
            onClick={() => setCreateCampaignModal(false)}
            className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium ${
              currentMode == "dark" ? "bg-black text-white" : "bg-white"
            } `}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setIsSingleCampaign(selectedDetails);
              setCreateCampaignModal(false);
              setCurrentCampaignDetails(null);
            }}
            className="p-3 px-7 rounded-[5px]  text-white bg-[#0A78BE]  border hover:border-[#F7D901]"
            disabled={!selectedDetails}
          >
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateCampaignModal;
