import React, { useEffect, useState } from "react";
import { BsMegaphone } from "react-icons/bs";
import { MdAdd, MdFilterList } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { PiGridFourLight, PiSquaresFour } from "react-icons/pi";
import { LuRectangleVertical } from "react-icons/lu";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import { FaRegFolder } from "react-icons/fa";
import CreateCampaignModal from "./CreateCampaign";
import SingleCampaign from "./SingleCampaign";
import CompaignsTable from "./CompaignsTable";
import AdSetsTable from "./adSetTable";
import AdsTable from "./AdsTable";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";

import SingleAdSet from "./SingleAdSet";
import CreateAdSetModal from "./createAdSet";
// import SingleAd from "./singleAd";
// import CreateAdModal from "./createAd";

const token = localStorage?.getItem("auth-token");
const fb_token =
  "EAAPVZATIw8YIBO9I4RrH4wUCDkTfZBLOkblkrkoGw2TrwahJtoCZByKTLCkwZB8t0b9ChIIR94oOlB8eEFiHFUlYU5Ht6l5JARyHMtcByS7CKNuXHqsL6YwY7G4FkV0MCfCFxMdKiyxWgSwi85VhCoYXkYvttOZAun1AYZBzxQXR4slpmIhzGFf0ePWthHq5pNNBLMlZAdNoqZArsCqiFAZDZD";
const oldObjectives = {
  awareness: [
    {
      label: "Awareness",
      banner:
        "https://release-manager.sc-cdn.net/218e1a0c4b913ae4d17c6404d31ae915.svg",
      content: {
        para: "Reach Snapchatters and create interest in your brand",
      },
      identifier: "BRAND_AWARENESS",
    },
    {
      label: "Promote Places",
      banner: "/assets/promote_places.png",
      content: {
        para: "Promote your places to Snapchatters",
      },
      identifier: "PROMOTE_PLACES",
    },
  ],
  consideration: [
    {
      label: "App Installs",
      banner: "/assets/app_installs.png",
      content: {
        para: "Send Snapchatters to the app store to download your app",
      },
      identifier: "APP_INSTALL",
    },
    {
      label: "Drive Traffic to Website",

      banner: "/assets/website_traffic.png",
      content: {
        para: "Send Snapchatters directly to your website",
      },
      identifier: "WEB_VIEW",
    },
    {
      label: "Drive Traffic to App",
      banner: "/assets/app_traffic.png",
      content: {
        para: "Send Snapchatters to your app or a third-party app",
      },
      identifier: "APP_REENGAGEMENT",
    },
    {
      label: "Engagement",

      banner: "/assets/engagement.png",
      content: {
        para: "Get more Snapchatters to engage with your ad",
      },
      identifier: "ENGAGEMENT",
    },
    {
      label: "Video Views",

      banner: "/assets/video_views.png",
      content: {
        para: "Promote your brand or product to Snapchatters through video. Learn more.",
      },
      identifier: "VIDEO_VIEW",
    },
    {
      label: "Lead Generation",

      banner: "/assets/leads.png",
      content: {
        para: "Generate leads for your business",
      },
      identifier: "LEAD_GENERATION",
    },
  ],
  conversions: [
    {
      label: "App Conversions",

      banner: "/assets/app_promotion.png",
      content: {
        para: "Drive specific actions within your app",
      },
      identifier: "APP_CONVERSION",
    },
    {
      label: "Website Conversions",

      banner: "/assets/app_promotion.png",
      content: {
        para: "Drive specific actions on your website",
      },
      identifier: "WEB_CONVERSION",
    },
  ],
};

const Compaigns = () => {
  const {
    currentMode,
    themeBgImg,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    fbCredentials,
    setFbCredentials,
  } = useStateContext();
  const [currentTable, setCurrentTable] = useState("campaigns");
  const [isCampaignModal, setIsCampaignModal] = useState(false);
  const [isSingleCampaign, setIsSingleCampaign] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [isSingleAdSet, setIsSingleAdSet] = useState(false);
  const [isSingleAd, setIsSingleAd] = useState(false);
  const [createAdSetModal, setCreateAdSetModal] = useState(false);
  const [adSetDetails, setAdSetDetails] = useState(null);
  const [adDetails, setAdDetails] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [adSets, setAdSets] = useState([]);
  const [ads, setAds] = useState([]);
  const [createAdModal, setCreateAdModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState({
    campaigns: [],
    adSets: [],
  });
  const [campData, setCampData] = useState({
    buying_type: "AUCTION",
    objective: "",
    name: "",
    special_ad_categories: "",
    status: "PAUSED",
    bid_strategy: "LOWEST_COST_WITHOUT_CAP",
    spend_cap: "",
    lifetime_budget: "",
    daily_budget: "",
    budget_schedule_specs: "",
  });

  console.log("campData: ", campData);

  const getAllCompaigns = async () => {
    setLoading(true);
    window.FB.api(
      `/${fbCredentials?.currentAdAccount}/campaigns`,
      "GET",
      {
        // access_token: fbCredentials?.access_token,
        access_token: fb_token,
        fields:
          "name,status,effective_status,insights{account_currency,buying_type,clicks,objective,spend},budget_remaining",
        effective_status: [
          "ACTIVE",
          "PAUSED",
          "ARCHIVED",
          "IN_PROCESS",
          "WITH_ISSUES",
          "PENDING_REVIEW",
          "PREAPPROVED",
          "PENDING_BILLING_INFO",
          "CAMPAIGN_PAUSED",
          "ADSET_PAUSED",
        ],
      },
      (response) => {
        if (!response || response?.error) {
          setLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${
              response.error
                ? response.error.message
                : "Error fetching campaigns"
            }`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          const campsData = response?.data?.map((camps) => ({
            id: camps?.id,
            status: camps?.status,
            name: camps?.name || "-",
            account_currency: camps?.insights?.data[0]?.account_currency || "-",
            budget_remaining: camps?.budget_remaining || "-",
            objective: camps?.insights?.data[0]?.objective || "-",
            spend: camps?.insights?.data[0]?.spend || "-",
            clicks: camps?.insights?.data[0]?.clicks || "-",
            buying_type: camps?.insights?.data[0]?.buying_type || "-",
            optimization_goal:
              camps?.insights?.data[0]?.optimization_goal || "-",
          }));
          setCampaigns(campsData);
          setLoading(false);
        }
      }
    );
  };

  const getAllAdSets = async () => {
    setLoading(true);

    window.FB.api(
      `/${fbCredentials?.currentAdAccount}/adsets`,
      "GET",
      {
        // access_token: fbCredentials?.access_token,
        access_token: fb_token,
        fields:
          "id,name,status,insights{objective,optimization_goal,reach,spend,impressions,clicks,account_currency,cpc},bid_amount,bid_strategy,budget_remaining,daily_budget,lifetime_budget",
        effective_status: [
          "ACTIVE",
          "PAUSED",
          "ARCHIVED",
          "IN_PROCESS",
          "WITH_ISSUES",
          "PENDING_REVIEW",
          "PREAPPROVED",
          "PENDING_BILLING_INFO",
          "CAMPAIGN_PAUSED",
          "ADSET_PAUSED",
        ],
      },
      (response) => {
        if (!response || response?.error) {
          setLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${
              response.error ? response.error.message : "Error fetching Adsets"
            }`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          const adsetData = response?.data?.map((adset) => ({
            id: adset?.id,
            status: adset?.status,
            name: adset?.name || "-",
            budget_remaining: adset?.budget_remaining || "-",
            bid_strategy: adset?.bid_strategy || "-",
            daily_budget: adset?.daily_budget || "-",
            lifetime_budget: adset?.lifetime_budget || "-",
            clicks: adset?.insights?.data[0]?.clicks || "-",
            cpc: adset?.insights?.data[0]?.cpc || "-",
            spend: adset?.insights?.data[0]?.spend || "-",
            reach: adset?.insights?.data[0]?.reach || "-",
            impressions: adset?.insights?.data[0]?.impressions || "-",
            account_currency: adset?.insights?.data[0]?.account_currency || "-",
            optimization_goal:
              adset?.insights?.data[0]?.optimization_goal || "-",
          }));
          setAdSets(adsetData);
          setLoading(false);
        }
      }
    );
  };

  const getAllAds = async () => {
    setLoading(true);

    window.FB.api(
      `/${fbCredentials?.currentAdAccount}/ads`,
      "GET",
      {
        // access_token: fbCredentials?.access_token,
        access_token: fb_token,
        fields: [
          "id",
          "name",
          "status",
          "effective_status",
          "insights{impressions,spend,cpc,reach,optimization_goal}",
        ].join(","),
        effective_status: [
          "ACTIVE",
          "PAUSED",
          "ARCHIVED",
          "IN_PROCESS",
          "WITH_ISSUES",
          "PENDING_REVIEW",
          "PREAPPROVED",
          "PENDING_BILLING_INFO",
          "CAMPAIGN_PAUSED",
          "ADSET_PAUSED",
        ],
      },
      (response) => {
        if (!response || response?.error) {
          setLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${response.error ? response.error.message : "Error fetching Ads"}`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          if (response?.data) {
            const adsData = response?.data?.map((ad) => ({
              id: ad?.id,
              status: ad?.status,
              name: ad?.name || "-",
              cpc: ad?.insights?.data[0]?.cpc || "-",
              spend: ad?.insights?.data[0]?.spend || "-",
              reach: ad?.insights?.data[0]?.reach || "-",
              impressions: ad?.insights?.data[0]?.impressions || "-",
              optimization_goal:
                ad?.insights?.data[0]?.optimization_goal || "-",
            }));
            setAds(adsData);
            setLoading(false);
          }
          setLoading(false);
        }
      }
    );
  };

  async function getAllAdSetsUnderCampaign(id) {
    console.log("single camp id: ", id);
    setLoading(true);

    window.FB.api(
      `/${id}/adsets`,
      "GET",
      {
        // access_token: fbCredentials?.access_token,
        access_token: fb_token,
        fields:
          "id,name,status,insights{objective,optimization_goal,reach,spend,impressions,clicks,account_currency,cpc},bid_amount,bid_strategy,budget_remaining,daily_budget,lifetime_budget",
        effective_status: [
          "ACTIVE",
          "PAUSED",
          "ARCHIVED",
          "IN_PROCESS",
          "WITH_ISSUES",
          "PENDING_REVIEW",
          "PREAPPROVED",
          "PENDING_BILLING_INFO",
          "CAMPAIGN_PAUSED",
          "ADSET_PAUSED",
        ],
      },
      (response) => {
        if (!response || response?.error) {
          setLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${response.error ? response.error.message : "Error fetching Ads"}`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          console.log("adsets list : ", response);
          // if (response?.data) {
          const adsetData = response?.data?.map((adset) => ({
            id: adset?.id,
            status: adset?.status,
            name: adset?.name || "-",
            budget_remaining: adset?.budget_remaining || "-",
            bid_strategy: adset?.bid_strategy || "-",
            daily_budget: adset?.daily_budget || "-",
            lifetime_budget: adset?.lifetime_budget || "-",
            clicks: adset?.insights?.data[0]?.clicks || "-",
            cpc: adset?.insights?.data[0]?.cpc || "-",
            spend: adset?.insights?.data[0]?.spend || "-",
            reach: adset?.insights?.data[0]?.reach || "-",
            impressions: adset?.insights?.data[0]?.impressions || "-",
            account_currency: adset?.insights?.data[0]?.account_currency || "-",
            optimization_goal:
              adset?.insights?.data[0]?.optimization_goal || "-",
          }));
          setAdSets(adsetData);
          setLoading(false);
          // }
          setLoading(false);
        }
      }
    );
  }

  async function getAllAdsUnderCampaign(id) {
    setLoading(true);

    window.FB.api(
      `/${id}/ads`,
      "GET",
      {
        // access_token: fbCredentials?.access_token,
        access_token: fb_token,
        fields: [
          "id",
          "name",
          "status",
          "effective_status",
          "insights{impressions,spend,cpc,reach,optimization_goal}",
        ].join(","),
        effective_status: [
          "ACTIVE",
          "PAUSED",
          "ARCHIVED",
          "IN_PROCESS",
          "WITH_ISSUES",
          "PENDING_REVIEW",
          "PREAPPROVED",
          "PENDING_BILLING_INFO",
          "CAMPAIGN_PAUSED",
          "ADSET_PAUSED",
        ],
      },
      (response) => {
        if (!response || response?.error) {
          setLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${
              response.error
                ? response.error.message
                : "Error fetching relevant ads."
            }`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          if (response?.data) {
            const adsData = response?.data?.map((ad) => ({
              id: ad?.id,
              status: ad?.status,
              name: ad?.name || "-",
              cpc: ad?.insights?.data[0]?.cpc || "-",
              spend: ad?.insights?.data[0]?.spend || "-",
              reach: ad?.insights?.data[0]?.reach || "-",
              impressions: ad?.insights?.data[0]?.impressions || "-",
              optimization_goal:
                ad?.insights?.data[0]?.optimization_goal || "-",
            }));
            setAds(adsData);
            setLoading(false);
          }
          setLoading(false);
        }
      }
    );
  }

  async function getAllAdsUnderAdSet(id) {
    setLoading(true);

    window.FB.api(
      `/${id}/ads`,
      "GET",
      {
        // access_token: fbCredentials?.access_token,
        access_token: fb_token,
        fields: [
          "id",
          "name",
          "status",
          "effective_status",
          "insights{impressions,spend,cpc,reach,optimization_goal}",
        ].join(","),
        effective_status: [
          "ACTIVE",
          "PAUSED",
          "ARCHIVED",
          "IN_PROCESS",
          "WITH_ISSUES",
          "PENDING_REVIEW",
          "PREAPPROVED",
          "PENDING_BILLING_INFO",
          "CAMPAIGN_PAUSED",
          "ADSET_PAUSED",
        ],
      },
      (response) => {
        if (!response || response?.error) {
          setLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${
              response.error
                ? response.error.message
                : "Error fetching relevant ads."
            }`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          if (response?.data) {
            const adsData = response?.data?.map((ad) => ({
              id: ad?.id,
              status: ad?.status,
              name: ad?.name || "-",
              cpc: ad?.insights?.data[0]?.cpc || "-",
              spend: ad?.insights?.data[0]?.spend || "-",
              reach: ad?.insights?.data[0]?.reach || "-",
              impressions: ad?.insights?.data[0]?.impressions || "-",
              optimization_goal:
                ad?.insights?.data[0]?.optimization_goal || "-",
            }));
            setAds(adsData);
            setLoading(false);
          }
          setLoading(false);
        }
      }
    );
  }

  async function createCampaign() {
    setBtnLoading(true);

    const filteredCampData = Object.entries(campData).filter(
      ([key, value]) => value !== "" && value !== null && value !== undefined
    );

    console.log("filtered camp params: ", filteredCampData);

    try {
      const response = await new Promise((resolve, reject) => {
        window.FB.api(
          `/${fbCredentials?.currentAdAccount}/campaigns`,
          "POST",
          {
            access_token: fb_token,
            ...campData,
          },
          (response) => {
            console.log("error in creating campaing: ", response);
            if (!response || response.error) {
              reject(
                response?.error?.error_user_msg ||
                  response?.error?.message ||
                  new Error("Unknown error occurred")
              );
            } else {
              resolve(response);
            }
          }
        );
      });

      console.log("Campaign created successfully:", response);

      toast.success("Campaign created successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setBtnLoading(false);
      setIsSingleCampaign(false);
      getAllCompaigns();
      return response; // Return the campaign details
    } catch (error) {
      setBtnLoading(false);

      console.error("Error creating campaign:", error);
      toast.error(
        `Error creating campaign: ${error || "Unknown error occurred"}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );

      throw error; // Handle the error in the calling function
    }
  }

  useEffect(() => {
    if (fbCredentials?.access_token) {
      getAllCompaigns();
    }
  }, [fbCredentials]);

  useEffect(() => {
    if (currentTable === "adSets" && selectedRows?.campaigns?.length > 0) {
      fetchAdSetsOfDifferentCampaings();
    } else if (fbCredentials?.access_token && currentTable === "adSets") {
      getAllAdSets();
    } else if (currentTable === "ads" && selectedRows?.adSets?.length > 0) {
      fetchAdsOfDifferentAdSets();
    } else if (currentTable === "ads" && selectedRows?.campaigns?.length > 0) {
      fetchAdsOfDifferentCampaings();
    } else if (fbCredentials?.access_token && currentTable === "ads") {
      getAllAds();
    }
  }, [currentTable]);

  async function fetchAdSetsOfDifferentCampaings() {
    try {
      const adSetsPromises = selectedRows?.campaigns?.map(async (id) => {
        console.log("selected camp id: ", id);
        try {
          return await getAllAdSetsUnderCampaign(id);
        } catch (error) {
          console.log(error);
          toast.error("Error fetching relevant adsets", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return [];
        }
      });

      const adSets = await Promise.all(adSetsPromises);
      // setAdSets(adSets.flat()); // Flatten the array of arrays
    } catch (error) {
      console.log(error, "this is an error");
      toast.error("Error fetching relevant adsets", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  async function fetchAdsOfDifferentCampaings() {
    try {
      const adsPromises = selectedRows?.campaigns?.map(async (id) => {
        try {
          return await getAllAdsUnderCampaign(id);
        } catch (error) {
          console.log(error);
          return [];
        }
      });

      const ads = await Promise.all(adsPromises);
      // setAds(ads.flat()); // Flatten the array of arrays
    } catch (error) {
      console.log(error, "this is an error");
    }
  }
  async function fetchAdsOfDifferentAdSets() {
    try {
      const adsPromises = selectedRows?.adSets?.map(async (id) => {
        try {
          return await getAllAdsUnderAdSet(id);
        } catch (error) {
          console.log(error);
          return [];
        }
      });

      const ads = await Promise.all(adsPromises);
      // setAds(ads.flat()); // Flatten the array of arrays
    } catch (error) {
      console.log(error, "this is an error");
    }
  }
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  function selectedCampaignsRemove() {
    setSelectedRows((pre) => ({ ...pre, campaigns: [] }));
  }
  function selectedAdSetsRemove() {
    setSelectedRows((pre) => ({ ...pre, adSets: [] }));
  }

  return (
    <>
      <div className={`px-6 ${getSummaryBgClass()}`}>
        {/* <div className="flex gap-1 py-9">
       
          <div
            className={`flex items-center  gap-8 py-6  px-5  ${getSummaryBgClass()} flex-1 rounded-md shadow-md`}
          >
            <div>
              <FiSearch size={16} />
            </div>
            <input
              type="text"
              className={`focus:outline-none flex-1 ${getSummaryBgClass()}`}
              placeholder="Search name or ID"
            />
          </div>
        </div> */}
        <div className="shadow-lg">
          <div
            className={`flex flex-col ${
              currentMode === "dark" ? "" : "bg-[#F6F0F0]"
            } justify-between  pt-5 px-5 border-b  border-b-gray-400 ${
              currentMode === "dark"
                ? ""
                : "bg-gradient-to-br from-[#F3EEF0] to-[#E7F0F9]"
            } ${getSummaryBgClass()}`}
          >
            <div className="flex gap-4 items-end">
              <div
                onClick={() => setCurrentTable("campaigns")}
                className={`flex  items-center  ${
                  currentMode === "dark"
                    ? currentTable === "campaigns"
                      ? "bg-dark-neu"
                      : "bg-dark"
                    : "bg-[#FFFFFF]"
                } rounded-t-lg py-3 ${
                  currentTable === "campaigns"
                    ? "h-[95%] text-[#0A78BE]"
                    : "h-[80%]"
                }  px-3 min-w-[220px] cursor-pointer justify-between`}
              >
                <div className="flex items-center gap-3">
                  <FaRegFolder size={18} />
                  <span
                    className={`font-medium text-[14px]  ${
                      currentTable === "campaigns" ? "text-[#0A78BE]" : ""
                    }`}
                  >
                    Campaigns
                  </span>
                </div>

                {selectedRows?.campaigns?.length > 0 && (
                  <div
                    onClick={selectedCampaignsRemove}
                    className="rounded-[5px] py-1 px-2 text-white bg-[#0A78BE] border flex items-center justify-between"
                  >
                    {selectedRows?.campaigns?.length} Selected
                    <IoMdClose size={16} />
                  </div>
                )}
              </div>
              <div
                onClick={() => setCurrentTable("adSets")}
                // className={`flex gap-3 items-center  bg-[#FFFFFF] rounded-t-lg py-3 px-3 min-w-[220px] cursor-pointer border-b-[3px]`}
                className={`flex justify-between items-center gap-3 ${
                  currentMode === "dark"
                    ? currentTable === "adSets"
                      ? "bg-dark-neu"
                      : "bg-dark"
                    : "bg-[#FFFFFF]"
                } rounded-t-lg py-3 ${
                  currentTable === "adSets"
                    ? "h-[95%] text-[#0A78BE]"
                    : "h-[80%]"
                }  px-3 min-w-[220px] justify-between cursor-pointer`}
              >
                <div className="flex items-center gap-3">
                  <PiSquaresFour size={18} />
                  <span
                    className={`font-medium text-[14px] ${
                      currentTable === "adSets" ? "text-[#0A78BE]" : ""
                    }`}
                  >
                    Ad set{" "}
                    {selectedRows?.campaigns?.length > 0 && (
                      <>for {selectedRows?.campaigns?.length} Campaigns</>
                    )}
                  </span>
                </div>
                {selectedRows?.adSets?.length > 0 && (
                  <div
                    onClick={selectedAdSetsRemove}
                    className="rounded-[5px] px-2 py-1 text-white bg-[#0A78BE] border flex items-center justify-center "
                  >
                    {selectedRows?.adSets?.length} Selected
                    <IoMdClose size={16} />
                  </div>
                )}
              </div>
              <div
                onClick={() => setCurrentTable("ads")}
                className={`flex gap-3 items-center  ${
                  currentMode === "dark"
                    ? currentTable === "ads"
                      ? "bg-dark-neu"
                      : "bg-dark"
                    : "bg-[#FFFFFF]"
                } rounded-t-lg py-3 ${
                  currentTable === "ads" ? "h-[95%] text-[#0A78BE]" : "h-[80%]"
                }  px-3 min-w-[220px] cursor-pointer `}
              >
                <LuRectangleVertical size={18} />
                <div>
                  <span
                    className={`font-medium text-[14px] ${
                      currentTable === "ads" ? "text-[#0A78BE]" : ""
                    }`}
                  >
                    {" "}
                    Ads{" "}
                    {selectedRows?.adSets?.length > 0 ? (
                      <>for {selectedRows?.adSets?.length} ad set</>
                    ) : (
                      selectedRows?.campaigns?.length > 0 && (
                        <>for {selectedRows?.campaigns?.length} Campaigns</>
                      )
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`flex gap-3 items-center ${getSummaryBgClass()} py-3 px-3`}
            >
              {currentTable == "campaigns" && (
                <>
                  <button
                    onClick={() => setIsCampaignModal(true)}
                    className="flex gap-1 items-center rounded-lg px-3 bg-[#006B4E] text-white p-3"
                  >
                    <MdAdd size={16} />
                    Create
                  </button>
                </>
              )}
              {currentTable == "adSets" && (
                <>
                  {/* setCreateAdSetModal(true) */}
                  <button
                    onClick={() => {
                      setCreateAdSetModal(true);
                      setAdDetails(null);
                    }}
                    className="flex gap-1 items-center rounded-lg px-3 bg-[#006B4E] text-white p-3"
                  >
                    <MdAdd size={16} />
                    Create
                  </button>
                </>
              )}
              {currentTable == "ads" && (
                <>
                  <button
                    // onClick={() => setIsSingleAd(true)}
                    onClick={() => setCreateAdModal(true)}
                    className="flex gap-1 items-center rounded-lg px-3 bg-[#006B4E] text-white p-3"
                  >
                    <MdAdd size={16} />
                    Create
                  </button>
                </>
              )}
            </div>
          </div>
          {currentTable === "campaigns" && (
            <CompaignsTable
              setIsSingleCampaign={setIsSingleCampaign}
              setCampaignDetails={setCampaignDetails}
              oldObjectives={oldObjectives}
              getAllCompaigns={getAllCompaigns}
              compaigns={campaigns}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              loading={loading}
              setLoading={setLoading}
              fb_token={fb_token}
            />
          )}
          {currentTable === "adSets" && (
            <AdSetsTable
              setAdSetDetails={setAdSetDetails}
              setIsSingleAdSet={setIsSingleAdSet}
              getAllAdSets={getAllAdSets}
              adSets={adSets}
              setAdSets={setAdSets}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              loading={loading}
              setLoading={setLoading}
              fb_token={fb_token}
            />
          )}
          {currentTable === "ads" && (
            <AdsTable
              setAdDetails={setAdDetails}
              setIsSingleAd={setIsSingleAd}
              getAllAds={getAllAds}
              ads={ads}
              setAds={setAds}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              loading={loading}
              setLoading={setLoading}
              fb_token={fb_token}
            />
          )}
        </div>
      </div>
      <CreateCampaignModal
        setCreateCampaignModal={setIsCampaignModal}
        createCampaignModal={isCampaignModal}
        setIsSingleCampaign={setIsSingleCampaign}
        oldObjectives={oldObjectives}
        setCurrentCampaignDetails={setCampaignDetails}
        campData={campData}
        setCampData={setCampData}
      />
      <SingleCampaign
        isSingleCampaign={isSingleCampaign}
        setIsSingleCampaign={setIsSingleCampaign}
        setCreateCampaignModal={setIsCampaignModal}
        currentCampaignDetails={campaignDetails}
        setCurrentCampaignDetails={setCampaignDetails}
        getAllCompaigns={getAllCompaigns}
        campData={campData}
        setCampData={setCampData}
        createCampaign={createCampaign}
        btnLoading={btnLoading}
        setBtnLoading={setBtnLoading}
      />
      <CreateAdSetModal
        setCreateAdSetModal={setCreateAdSetModal}
        createAdSetModal={createAdSetModal}
        setIsSingleAdSet={setIsSingleAdSet}
        setAdSetDetails={setAdSetDetails}
      />
      <SingleAdSet
        isSingleAdSet={isSingleAdSet}
        setIsSingleAdSet={setIsSingleAdSet}
        currentAdSetDetails={adSetDetails}
        getAllAdSets={getAllAdSets}
      />

      {/*    <SingleAd
        isSingleAd={isSingleAd}
        setIsSingleAd={setIsSingleAd}
        currentAdDetails={adDetails}
        getAllAds={getAllAds}
      />
      <CreateAdModal
        setCreateAdModal={setCreateAdModal}
        createAdModal={createAdModal}
        setIsSingleAd={setIsSingleAd}
        setAdDetails={setAdDetails}
      /> */}
    </>
  );
};

export default Compaigns;
