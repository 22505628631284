import * as React from "react";
import Menu from "@mui/material/Menu";
import { useStateContext } from "../../context/ContextProvider";
import { IoMdArrowUp, IoMdArrowDown } from "react-icons/io";
import HierarchicalTree from "./TreeView";

export default function DetailedTargeting({ anchorEl, setAnchorEl }) {
  const open = Boolean(anchorEl);
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    setSnapchatCredentials,
  } = useStateContext();

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? "bg-dark text-white"
      : "bg-white text-black";
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        // transformOrigin={{ horizontal: "right", vertical: "top" }}
        // anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiList-root": {
            padding: "0px !important", // Modify border radius
            width: "600px",
          },
          padding: "0px",
        }}
      >
        <div className={` ${getSummaryBgClass()} py-4`}>
          <HierarchicalTree />
        </div>
      </Menu>
    </div>
  );
}
