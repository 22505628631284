import React, { useEffect, useState, useRef } from "react";

import { toast } from "react-toastify";
import { AiOutlineHistory } from "react-icons/ai";
import {
  TextField,
  CircularProgress,
  Tooltip,
  Modal,
  Backdrop,
  InputAdornment,
  FormControlLabel,
  Switch,
  Checkbox,
  Box,
} from "@mui/material";
import moment from "moment/moment";
import CustomSwitch from "./customSwitch";

import axios from "../../axoisConfig";
import usePermission from "../../utils/usePermission";
import { useStateContext } from "../../context/ContextProvider";
import { MdClose } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
const style = {
  transform: "translate(0%, 0%)",
  boxShadow: 24,
};
const stripeStyle = {
  backgroundColor: "#e5e7eb", // Light gray background
  backgroundImage:
    "linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent)",
  backgroundSize: "20px 20px", // Size of the stripes
};
const token = localStorage?.getItem("auth-token");
const SingleAdSet = ({
  isSingleAdSet,
  setIsSingleAdSet,
  currentAdSetDetails,
  getAllAdSets,
}) => {
  const {
    isLangRTL,
    BACKEND_URL,
    i18n,
    currentMode,
    t,
    snapchatCredentials,
    themeBgImg,
    darkModeColors,
  } = useStateContext();
  const [isClosing, setIsClosing] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isChangesOccured, setIsChangesOccured] = useState(false);
  // const [bidStrategy, setBidStrategy] = useState("AUTO_BID");
  const [adSetDetails, setAdSetDetails] = useState({
    name: "",
    status: false,
    budget: null,
    isBidMicro: false,
    bidMicro: null,
    bidStrategy: "AUTO_BID",
  });
  const [localData, setLocalData] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  });
  const handleClose = () => {
    setIsClosing(true);

    setTimeout(() => {
      setIsClosing(false);
      setIsSingleAdSet(false);
    }, 1000);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  useEffect(() => {
    if (currentAdSetDetails) {
      const {
        name,
        start_time,
        end_time,
        daily_budget_micro,
        status,
        bid_micro,
        bid_strategy,
      } = currentAdSetDetails;
      console.log(currentAdSetDetails, "details of ad set");
      const fullStart = dayjs(start_time);
      const fullEnd = end_time ? dayjs(end_time) : undefined;

      setAdSetDetails({
        ...(daily_budget_micro
          ? {
              budget: daily_budget_micro / 1000000,
            }
          : { budget: "" }),
        name: name,
        status: status === "ACTIVE",
        bidMicro: bid_strategy === "AUTO_BID" ? null : bid_micro / 1000000,
        bidStrategy: bid_strategy,
        isBidMicro: bid_strategy === "AUTO_BID" ? false : true,
      });
      setLocalData({
        startDate: fullStart.startOf("day"), // Set time to 00:00:00
        startTime: fullStart,
        ...(fullEnd?.startOf("day") && { endDate: fullEnd.startOf("day") }),
        ...(fullEnd?.startOf("day") && { endTime: fullEnd }),
      });

      setChecked(Boolean(fullEnd?.startOf("day")));
    } else {
      setLocalData({});
      setAdSetDetails({
        name: "",
        status: false,
        budget: "",
      });

      setChecked(false);
    }
  }, [currentAdSetDetails]);
  useEffect(() => {
    if (currentAdSetDetails) {
      const {
        name,
        start_time,
        end_time,
        daily_budget_micro,
        status,
        bid_strategy,
        bid_micro,
      } = currentAdSetDetails;
      const {
        name: Name,
        budget,
        status: localStatus,
        bidStrategy,
        bidMicro,
      } = adSetDetails;
      console.log(
        Name,
        name,
        localStatus ? "ACTIVE" : "PAUSED",
        status,
        budget,
        daily_budget_micro ? daily_budget_micro / 1000000 : "",
        bidStrategy,
        bid_strategy,
        bidMicro,
        bidMicro != bid_micro ? bid_micro / 1000000 : null,
        "Values"
      );
      if (
        Name != name ||
        budget != (daily_budget_micro ? daily_budget_micro / 1000000 : "") ||
        status != (localStatus ? "ACTIVE" : "PAUSED") ||
        bidStrategy != bid_strategy ||
        bidMicro != (bid_micro ? bid_micro / 1000000 : null) ||
        checked != Boolean(end_time)
      ) {
        setIsChangesOccured(true);
        // alert("it is called in normal change");
      } else {
        const startDate = dayjs(localData?.startDate.$d).format("YYYY-MM-DD");
        const startTime = dayjs(localData?.startTime.$d).format("HH:mm:ss.SSS");

        const endDate = dayjs(localData?.endDate?.$d).format("YYYY-MM-DD");
        const endTime = dayjs(localData?.endTime?.$d).format("HH:mm:ss.SSS");
        const START_TIME = localData?.startDate
          ? new Date(`${startDate}T${startTime}`)?.toISOString()
          : null;
        const END_TIME = localData?.endDate
          ? new Date(`${endDate}T${endTime}`)?.toISOString()
          : null;
        if (START_TIME != start_time || END_TIME != end_time) {
          setIsChangesOccured(true);
          alert("it is called in time change");
        } else {
          setIsChangesOccured(false);
        }
      }
    }
  }, [adSetDetails, localData, checked]);

  async function createAdSet() {
    try {
      const startDate = dayjs(localData?.startDate?.$d).format("YYYY-MM-DD");
      const startTime = dayjs(localData?.startTime?.$d).format("HH:mm:ss.SSS");

      const endDate = dayjs(localData?.endDate?.$d).format("YYYY-MM-DD");
      const endTime = dayjs(localData?.endTime?.$d).format("HH:mm:ss.SSS");

      await axios.post(
        `${BACKEND_URL}/snapchat/create-adsquads`,
        {
          access_token: snapchatCredentials?.access_token,
          campaign_id: isSingleAdSet,
          name: adSetDetails?.name,
          status: adSetDetails?.status ? "ACTIVE" : "PAUSED",
          start_time: new Date(`${startDate}T${startTime}`)?.toISOString(),
          ...(endDate &&
            checked && {
              end_time: new Date(`${endDate}T${endTime}`)?.toISOString(),
            }),
          daily_budget_micro: adSetDetails?.budget,
          ...(adSetDetails?.bidStrategy !== "AUTO_BID" && {
            bid_micro: adSetDetails?.bidMicro,
          }),
          bid_strategy: adSetDetails?.bidStrategy,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      toast.success("Successfully Created AdSet", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      getAllAdSets();
    } catch (error) {
      console.log(error, "this is an error");
      toast.error("Unable to create adSet", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      if (error.response?.data?.errors) {
        Object.values(error.response?.data?.errors)
          .flat()
          .map((err) =>
            toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
          );
      }
    }
  }
  async function updateAdSet() {
    try {
      const startDate = dayjs(localData?.startDate?.$d).format("YYYY-MM-DD");
      const startTime = dayjs(localData?.startTime?.$d).format("HH:mm:ss.SSS");

      const endDate = dayjs(localData?.endDate?.$d).format("YYYY-MM-DD");
      const endTime = dayjs(localData?.endTime?.$d).format("HH:mm:ss.SSS");

      await axios.post(
        `${BACKEND_URL}/snapchat/update-adsquad`,
        {
          access_token: snapchatCredentials?.access_token,
          campaign_id: currentAdSetDetails?.campaign_id,
          ad_squad_id: currentAdSetDetails?.id,
          name: adSetDetails?.name,
          status: adSetDetails?.status ? "ACTIVE" : "PAUSED",
          start_time: new Date(`${startDate}T${startTime}`)?.toISOString(),
          ...(endDate &&
            checked && {
              end_time: new Date(`${endDate}T${endTime}`)?.toISOString(),
            }),
          daily_budget_micro: adSetDetails?.budget,
          ...(adSetDetails?.bidStrategy !== "AUTO_BID" && {
            bid_micro: adSetDetails?.bidMicro,
          }),
          bid_strategy: adSetDetails?.bidStrategy,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      toast.success("Successfully Updated AdSet", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      getAllAdSets();
    } catch (error) {
      console.log(error, "this is an error");
      toast.error("Unable to update adSet", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      if (error.response?.data?.errors) {
        Object.values(error.response?.data?.errors)
          .flat()
          .map((err) =>
            toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
          );
      }
    }
  }
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  return (
    <>
      <Modal
        // keepMounted
        open={isSingleAdSet}
        onClose={() => setIsSingleAdSet(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={`${
            isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
          } ${
            isClosing
              ? isLangRTL(i18n.language)
                ? "modal-close-left"
                : "modal-close-right"
              : ""
          }
        w-[100vw] h-[100vh] flex items-start justify-end `}
        >
          <button
            // onClick={handleCloseTimelineModel}
            onClick={handleClose}
            className={`${
              isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
            }
            bg-primary w-fit h-fit p-3 my-4 z-10`}
          >
            <MdClose
              size={18}
              color={"white"}
              className=" hover:border hover:border-white hover:rounded-full"
            />
          </button>
          {/* ${
              currentMode === "dark"
                ? "bg-[#000000] text-white"
                : "bg-[#F9F9FA] text-black"
            }  */}
          <div
            style={style}
            className={`${getSummaryBgClass()} ${
              isLangRTL(i18n.language)
                ? currentMode === "dark" && " border-primary border-r-2"
                : currentMode === "dark" && " border-primary border-l-2"
            } 
             pt-4 h-[100vh] w-[80vw] overflow-y-scroll border-primary px-7 flex flex-col relative
            `}
          >
            {/* className="bg-white rounded-3xl px-8 py-4 shadow-md mb-5" */}
            <h2 className="font-bold text-[16px] mb-5">Ad Set Details</h2>
            <div
              className={`${getSummaryBgClass()}  rounded-3xl px-8 py-4  shadow-md mb-5`}
            >
              <div className="flex flex-col">
                <label htmlFor="" className="font-medium">
                  Ad Set Name
                </label>
                <input
                  type="text"
                  // className="p-2 py-3 mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                  className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
                  value={adSetDetails?.name}
                  onChange={(e) =>
                    setAdSetDetails((pre) => ({
                      ...pre,
                      name: e?.target?.value,
                    }))
                  }
                />
              </div>
            </div>
            <h2 className="font-bold text-[16px] mb-5">Budget & Schedule</h2>
            {/* <div className="bg-white rounded-3xl px-8 py-4 shadow-md mb-5"> */}
            <div
              className={`${getSummaryBgClass()}  rounded-3xl px-8 py-4  shadow-md mb-5`}
            >
              {/* <div className="flex flex-col">
                <label htmlFor="" className="font-medium">
                  Ad Set Name
                </label>
                <input
                  type="text"
                  className="p-2 py-3 mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                  //   value={campaignDetails?.campaignName}
                  //   onChange={(e) =>
                  //     setCampaignDetials((pre) => ({
                  //       ...pre,
                  //       campaignName: e?.target?.value,
                  //     }))
                  //   }
                />
              </div> */}
              <div className="grid grid-cols-2 gap-6 mb-5">
                <div className="flex flex-col">
                  <label htmlFor="">Budget</label>
                  <div className="relative w-full">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                      $
                    </span>
                    <input
                      type="number"
                      // className="pl-6 p-2 py-3 w-full mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                      className={`pl-6 p-2 py-3 w-full mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA]`}
                      value={adSetDetails?.budget}
                      placeholder="No cap"
                      onChange={(e) =>
                        setAdSetDetails((pre) => ({
                          ...pre,
                          budget: e?.target?.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="" className="font-medium invisible">
                    {" "}
                    slkdf
                  </label>
                  <input
                    type="text"
                    // className="p-2 py-3 mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                    className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
                    value={"Daily Budget"}
                    disabled={true}
                    //   onChange={(e) =>
                    //     setCampaignDetials((pre) => ({
                    //       ...pre,
                    //       campaignName: e?.target?.value,
                    //     }))
                    //   }
                  />
                </div>
              </div>
              <Box
                sx={{
                  ...darkModeColors,
                  "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                    {
                      right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                      transformOrigin: isLangRTL(i18n.language)
                        ? "right"
                        : "left",
                    },
                  "& legend": {
                    textAlign: isLangRTL(i18n.language) ? "right" : "left",
                  },
                }}
              >
                <div className="grid grid-cols-2 gap-6 items-center py-4">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={localData?.startDate || null}
                      label={t("start_date")}
                      views={["day", "month", "year"]}
                      onChange={(val) =>
                        setLocalData((pre) => ({ ...pre, startDate: val }))
                      }
                      format="DD-MM-YYYY"
                      minDate={dayjs()} // Restrict to future dates
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            "& input": {
                              color: currentMode === "dark" ? "white" : "black",
                            },
                            "& .MuiSvgIcon-root": {
                              color: currentMode === "dark" ? "white" : "black",
                            },
                            // "& .MuiOutlinedInput-notchedOutline": {
                            //   borderColor:
                            //     fieldErrors?.date === true &&
                            //     "#DA1F26 !important",
                            // },
                            // marginBottom: "20px",
                          }}
                          fullWidth
                          // size="small"
                          {...params}
                          onKeyDown={(e) => e.preventDefault()}
                          readOnly={true}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Select time"
                      value={localData?.startTime || null}
                      onChange={(newValue) => {
                        setLocalData((pre) => ({
                          ...pre,
                          startTime: newValue,
                        }));
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox checked={checked} onChange={handleChange} />
                    }
                    label="Set an End Date (Optional)"
                  />
                </div>
                {checked && (
                  <div className="grid grid-cols-2 gap-6 items-center py-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={localData?.endDate || null}
                        label={t("end_date")}
                        views={["day", "month", "year"]}
                        onChange={(val) =>
                          setLocalData((pre) => ({ ...pre, endDate: val }))
                        }
                        format="DD-MM-YYYY"
                        minDate={localData?.startDate || dayjs()} // End date should be after start date
                        disabled={!localData?.startDate}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "& input": {
                                color:
                                  currentMode === "dark" ? "white" : "black",
                              },
                              "& .MuiSvgIcon-root": {
                                color:
                                  currentMode === "dark" ? "white" : "black",
                              },
                              // "& .MuiOutlinedInput-notchedOutline": {
                              //   borderColor:
                              //     fieldErrors?.date === true &&
                              //     "#DA1F26 !important",
                              // },
                              // marginBottom: "20px",
                            }}
                            fullWidth
                            // size="small"
                            {...params}
                            onKeyDown={(e) => e.preventDefault()}
                            readOnly={true}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Select time"
                        value={localData?.endTime || null}
                        onChange={(newValue) => {
                          setLocalData((pre) => ({
                            ...pre,
                            endTime: newValue,
                          }));
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </Box>
              <div>
                <label htmlFor="">Bid</label>
                <ul>
                  <li
                    onClick={() =>
                      setAdSetDetails((pre) => ({
                        ...pre,
                        isBidMicro: false,
                        bidStrategy: "AUTO_BID",
                      }))
                    }
                    className={`group flex gap-3 items-center border border-gray-300  ${
                      currentMode === "dark"
                        ? "bg-dark-neu text-white"
                        : "bg-[#F9F9FA]"
                    } rounded-lg py-4 px-3 mb-3`}
                  >
                    <div
                      className={`w-[16px] h-[16px] rounded-full border border-gray-700 ${
                        !adSetDetails?.isBidMicro
                          ? "border-[5px]"
                          : "group-hover:border-[3px]"
                      }  `}
                    ></div>
                    <div>
                      <h4>Auto Bid (Recommented)</h4>
                      <p>
                        Makes best effort to spend your budget. Try this
                        strategy if you want to learn what cost per action you
                        can achieve on Snap.
                      </p>
                    </div>
                  </li>
                  <li
                    onClick={() =>
                      setAdSetDetails((pre) => ({
                        ...pre,
                        isBidMicro: true,
                        bidStrategy: "LOWEST_COST_WITH_MAX_BID",
                      }))
                    }
                    className={`${
                      currentMode === "dark"
                        ? "bg-dark-neu text-white"
                        : "bg-[#F9F9FA]"
                    } border border-gray-300  rounded-lg py-4 px-3`}
                  >
                    <div className="group flex gap-3 items-center">
                      <div
                        className={`w-[16px] h-[16px] rounded-full border border-gray-700 ${
                          adSetDetails?.isBidMicro
                            ? "border-[5px]"
                            : "group-hover:border-[3px]"
                        }  `}
                      ></div>
                      <div className="w-full">
                        <h4>Max Bid</h4>
                        <p>Bids conservatively at or below your desired bid.</p>
                      </div>
                    </div>

                    {adSetDetails?.isBidMicro && (
                      <div className="relative w-full pl-4">
                        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                          per Click
                        </span>
                        <input
                          type="number"
                          // className="pl-6 p-2 py-3 w-full mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                          className={`pl-6 p-2 py-3 w-full mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA]`}
                          value={adSetDetails?.bidMicro}
                          placeholder="No cap"
                          onChange={(e) =>
                            setAdSetDetails((pre) => ({
                              ...pre,
                              bidMicro: e?.target?.value,
                            }))
                          }
                        />
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <h2 className="font-bold text-[16px] mb-5">Delivery</h2>
            <div
              className={`${getSummaryBgClass()} rounded-3xl px-8 py-4 shadow-md mb-9`}
            >
              <div className="flex items-end">
                <FormControlLabel
                  value=""
                  control={
                    <CustomSwitch
                      color="primary"
                      size="medium"
                      checked={adSetDetails?.status}
                      onChange={(e) =>
                        setAdSetDetails((pre) => ({
                          ...pre,
                          status: e?.target?.checked,
                        }))
                      }
                    />
                  }
                  label="Status"
                  labelPlacement="top"
                  sx={{
                    margin: "0px",
                    marginTop: `10px`,
                  }}
                />
                <span className="pb-[10px] pl-1">
                  {adSetDetails?.status ? "ACTIVE" : "PAUSED"}
                </span>
              </div>

              <div className="flex flex-col "></div>
            </div>
            <div
              className={`flex justify-end py-5 ${getSummaryBgClass()} shadow-md px-4 mt-3`}
              // style={{ width: "calc(100% - 40px)" }}
            >
              {!currentAdSetDetails && (
                <button
                  onClick={() => createAdSet()}
                  // className="p-3 px-5 hover:border-[#F7D901] rounded-[20px] disabled:cursor-not-allowed border font-semibold bg-white"
                  className={`p-3 px-5 hover:border-[#F7D901] rounded-[20px] disabled:cursor-not-allowed border font-semibold ${
                    currentMode === "dark" ? "bg-black text-white" : "bg-white"
                  }`}
                  disabled={
                    !adSetDetails?.name ||
                    !localData?.startDate ||
                    !localData?.startTime ||
                    (checked && (!localData?.endDate || !localData?.endTime)) ||
                    (adSetDetails?.isBidMicro && !adSetDetails?.bidMicro) ||
                    !adSetDetails?.budget
                  }
                >
                  Next
                </button>
              )}

              {currentAdSetDetails && (
                <button
                  style={
                    isChangesOccured
                      ? { backgroundColor: "black", color: "white" }
                      : currentMode === "dark"
                      ? { backgroundColor: "#282b30", color: "white" }
                      : stripeStyle
                  }
                  className={`p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold ${
                    currentMode === "dark" ? "bg-black text-white" : "bg-white"
                  } `}
                  disabled={!isChangesOccured}
                  onClick={() => updateAdSet()}
                >
                  Save Changes
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SingleAdSet;
