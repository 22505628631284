import React, { useEffect, useState, useRef } from "react";

import { toast } from "react-toastify";
import {
  TextField,
  Modal,
  Backdrop,
  FormControlLabel,
  Switch,
  Checkbox,
  Box,
  FormGroup,
} from "@mui/material";
import moment from "moment/moment";
import axios from "../../axoisConfig";
import { useStateContext } from "../../context/ContextProvider";
import { MdClose } from "react-icons/md";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { selectStyles } from "../_elements/SelectStyles.jsx";
import dayjs from "dayjs";
import Select from "react-select";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { IoIosArrowUp } from "react-icons/io";
import { IoAddCircleOutline, IoSearch } from "react-icons/io5";
import TimeScheduleView from "./TimeScheduleView.js";
import { FaMinus } from "react-icons/fa";
import { TiArrowSortedDown } from "react-icons/ti";
import Scheduler from "./timeFrame.js";
import LocationSelector from "./location.js";
import { RxCross2 } from "react-icons/rx";
import Audiences from "./audience.js";
import Placements from "./Placements.js";

const style = {
  transform: "translate(0%, 0%)",
  boxShadow: 24,
};

const stripeStyle = {
  backgroundColor: "#e5e7eb", // Light gray background
  backgroundImage:
    "linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent)",
  backgroundSize: "20px 20px", // Size of the stripes
};
const token = localStorage?.getItem("auth-token");
const performanceGoals = [
  {
    label: "Maximise Reach of ads",
    value: "1",
  },
  {
    label: "Maximise number of impressions",
    value: "2",
  },
  {
    label: "Maximise ad recall lift",
    value: "3",
  },
  {
    label: "Maximise ThruPlay views",
    value: "4",
  },
  {
    label: "Maximise 2-second continuous video plays",
    value: "5",
  },
];
const chargedTypes = [
  {
    label: "Impression",
    value: 1,
  },
  {
    label: "2-second continuous video view",
    value: 2,
  },
];
const budgetTypes = [
  {
    label: "Daily Budget",
    value: 1,
  },
  {
    label: "Lifetime Budget",
    value: 2,
  },
];
const dailyBudgetTypes = [
  {
    label: "Increase daily budget by value amount (Rs) ",
    value: 1,
  },
  {
    label: "Increase daily budget by value percentage (%)",
    value: 2,
  },
];
const mobileDevices = [
  {
    label: "All mobile devices",
    value: 1,
  },
  {
    label: "Android Devices only",
    value: 2,
  },
  {
    label: "iOS devices only",
    value: 3,
  },
];
const minAges = [18, 19, 20, 21, 22, 23, 24, 25].map((age) => ({
  label: `${age}`,
  value: age,
}));

const devices = [
  { label: "Mobile", value: 1 },
  { label: "Desktop", value: 2 },
];

const SingleAdSet = ({
  isSingleAdSet,
  setIsSingleAdSet,
  currentAdSetDetails,
  getAllAdSets,
}) => {
  const {
    isLangRTL,
    BACKEND_URL,
    i18n,
    currentMode,
    t,
    snapchatCredentials,
    themeBgImg,
    darkModeColors,
    primaryColor,
  } = useStateContext();
  const [isClosing, setIsClosing] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isChangesOccured, setIsChangesOccured] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [selectedConversionLocation, setSelectedConversionLocation] =
    useState(null);
  // const [bidStrategy, setBidStrategy] = useState("AUTO_BID");
  const [adSetDetails, setAdSetDetails] = useState({
    name: "",
    status: false,
    budget: null,
    isBidMicro: false,
    bidMicro: null,
    bidStrategy: "AUTO_BID",
  });
  const [localData, setLocalData] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  });
  const handleClose = () => {
    setIsClosing(true);

    setTimeout(() => {
      setIsClosing(false);
      setIsSingleAdSet(false);
    }, 1000);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  const placementTypes = [
    {
      label: "Advantage+ placements (Recommended)",
      value: 1,
      para: "Advantage+ placements (Recommended) Use Advantage+ placements to maximise your budget and help show your ads to more people. Facebook's delivery system will allocate your ad set's budget across multiple placements based on where they're likely to perform best.",
    },
    {
      label: "Manual placements",
      value: 2,
      para: "Manually choose the places to show your ad. The more placements you select, the more opportunities you'll have to reach your target audience and achieve your business goals.",
    },
  ];
  const inventoryFilters = [
    {
      label: "Expanded inventory",
      value: 1,
      para: "Show ads on all content that adhere to our Content Monetisation Policies so that you get the most reach.",
    },
    {
      label: "Moderate inventory",
      value: 2,
      para: "Exclude highly sensitive content",
    },
    {
      label: "Limited inventory",
      value: 3,
      para: "Exclude additional sensitive content, as well as all live videos. This lowers reach and can increase costs.",
    },
  ];

  const conversionLocations = [
    {
      label: "Website",
      value: 1,
      para: "Send traffic to your website.",
    },
    {
      label: "App",
      value: 2,
      para: "Send traffic to your app.",
    },
    {
      label: "Messaging apps",
      value: 3,
      para: "Send traffic to Messenger, Instagram and WhatsApp.",
    },
    {
      label: "Instagram profile",
      value: 4,
      para: "Send traffic to your Instagram profile.",
    },
    {
      label: "Calls",
      value: 5,
      para: "Get people to call your business.",
    },
  ];

  const objectiveTypes = [
    "awareness",
    "traffic",
    "engagement",
    "leads",
    "app_promotion",
    "sales",
  ];
  const selectedObjective = objectiveTypes[1];

  if (selectedObjective === "engagement") {
    conversionLocations.push(
      {
        label: "On your ad",
        value: 6,
        para: "Get people to watch a video or interact with your post or event.",
      },
      {
        label: "Facebook Page",
        value: 6,
        para: "Get people to engage with your Facebook Page.",
      }
    );
  }
  if (selectedObjective === "leads") {
    conversionLocations.push(
      {
        label: "Instant Forms",
        value: 6,
        para: "Generate leads by asking people to fill in a form.",
      },
      {
        label: "Instant Forms and Messenger",
        value: 7,
        para: "Generate leads by asking people to fill in a form or by starting chats in Messenger.",
      }
    );
  }

  return (
    <>
      <Modal
        // keepMounted
        open={isSingleAdSet}
        onClose={() => setIsSingleAdSet(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={`${
            isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
          } ${
            isClosing
              ? isLangRTL(i18n.language)
                ? "modal-close-left"
                : "modal-close-right"
              : ""
          }
        w-[100vw] h-[100vh] flex items-start justify-end `}
        >
          <button
            // onClick={handleCloseTimelineModel}
            onClick={handleClose}
            className={`${
              isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
            }
            bg-primary w-fit h-fit p-3 my-4 z-10`}
          >
            <MdClose
              size={18}
              color={"white"}
              className=" hover:border hover:border-white hover:rounded-full"
            />
          </button>
          {/* ${
              currentMode === "dark"
                ? "bg-[#000000] text-white"
                : "bg-[#F9F9FA] text-black"
            }  */}
          <div
            style={style}
            className={` ${getSummaryBgClass()} ${
              currentMode === "dark"
                ? ""
                : "bg-gradient-to-br from-[#F3EEF0] to-[#E7F0F9]"
            } ${
              isLangRTL(i18n.language)
                ? currentMode === "dark" && " border-primary border-r-2"
                : currentMode === "dark" && " border-primary border-l-2"
            } 
             pt-4 h-[100vh] w-[80vw] overflow-y-scroll border-primary px-7 flex flex-col gap-4 relative
            `}
          >
            {/* className="bg-white rounded-3xl px-8 py-4 shadow-md mb-5" */}
            <h2 className="font-bold text-[16px] mb-5">Ad Set Details</h2>

            <div
              className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4`}
            >
              <label htmlFor="" className="text-[14px] font-semibold mb-3">
                Ad set name
              </label>
              <input
                type="text"
                className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                // value={campaignDetails?.campaignName}
                // onChange={(e) =>
                //   setCampaignDetials((pre) => ({
                //     ...pre,
                //     campaignName: e?.target?.value,
                //   }))
                // }
              />
            </div>

            <div
              className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4`}
            >
              <label htmlFor="" className="text-[14px] font-semibold mb-3">
                {selectedObjective !== "awareness" &&
                selectedObjective !== "app_promotion"
                  ? "Convertion"
                  : selectedObjective == "awareness"
                  ? "Awareness"
                  : "App"}
              </label>
              {selectedObjective === "traffic" && (
                <div>
                  <label htmlFor="" className="text-[12px] font-semibold mb-3">
                    Conversion location
                  </label>
                  <div className="mb-4 rounded p-2">
                    <p>
                      Choose where you want to drive traffic. You'll enter more
                      details about the destination later.
                    </p>
                    <ul className="pl-4 pt-4 w-1/2 px-4">
                      {conversionLocations?.map((objective) => {
                        return (
                          <li
                            className={`flex gap-4 items-center mb-8 group cursor-pointer px-2 py-2 rounded-lg  ${
                              selectedConversionLocation?.label ===
                              objective?.label
                                ? "bg-[#C3DCF5]"
                                : ""
                            }`}
                            onClick={() =>
                              setSelectedConversionLocation(objective)
                            }
                          >
                            {" "}
                            <div
                              className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
                                selectedConversionLocation?.label ===
                                objective?.label
                                  ? "border-[5px]"
                                  : "group-hover:border-[3px]"
                              }  `}
                            ></div>
                            <div className="flex-1">
                              <h3
                                className={`text-[14px] ${
                                  currentMode === "dark"
                                    ? selectedConversionLocation?.label ===
                                      objective?.label
                                      ? "text-black"
                                      : "text-white"
                                    : "text-black"
                                }`}
                              >
                                {objective?.label}
                              </h3>
                              <p>{objective?.para}</p>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
              {selectedObjective === "app_promotion" && (
                <>
                  <h3 className="text-[14px] font-semibold my-3">
                    Mobile app store
                  </h3>
                  <div className="w-full ">
                    <Select
                      // isLoading={adSetLoading}
                      id="goals"
                      options={performanceGoals}
                      // value={adDetails?.publicProfile}
                      // onChange={(e) => {
                      //   setAdDetails((pre) => ({
                      //     ...pre,
                      //     publicProfile: e,
                      //   }));
                      // }}
                      placeholder={"Select Store"}
                      // className={`mb-5`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                  </div>{" "}
                  <h3 className="text-[14px] font-semibold my-3">App</h3>
                  <div className="w-full ">
                    <Select
                      // isLoading={adSetLoading}
                      id="goals"
                      options={performanceGoals}
                      // value={adDetails?.publicProfile}
                      // onChange={(e) => {
                      //   setAdDetails((pre) => ({
                      //     ...pre,
                      //     publicProfile: e,
                      //   }));
                      // }}
                      placeholder={"Select App"}
                      // className={`mb-5`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                  </div>
                </>
              )}
              <h3 className="text-[14px] font-semibold my-3">
                Performance goal
              </h3>
              <div className="w-full ">
                <Select
                  // isLoading={adSetLoading}
                  id="goals"
                  options={performanceGoals}
                  // value={adDetails?.publicProfile}
                  // onChange={(e) => {
                  //   setAdDetails((pre) => ({
                  //     ...pre,
                  //     publicProfile: e,
                  //   }));
                  // }}
                  placeholder={"Select Performance goal"}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
              </div>
              {selectedConversionLocation?.label === "App" && (
                <>
                  <h3 className="text-[14px] font-semibold my-3">
                    Mobile app store
                  </h3>
                  <div className="w-full ">
                    <Select
                      // isLoading={adSetLoading}
                      id="goals"
                      options={performanceGoals}
                      // value={adDetails?.publicProfile}
                      // onChange={(e) => {
                      //   setAdDetails((pre) => ({
                      //     ...pre,
                      //     publicProfile: e,
                      //   }));
                      // }}
                      placeholder={"Select Store"}
                      // className={`mb-5`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                  </div>{" "}
                  <h3 className="text-[14px] font-semibold my-3">App</h3>
                  <div className="w-full ">
                    <Select
                      // isLoading={adSetLoading}
                      id="goals"
                      options={performanceGoals}
                      // value={adDetails?.publicProfile}
                      // onChange={(e) => {
                      //   setAdDetails((pre) => ({
                      //     ...pre,
                      //     publicProfile: e,
                      //   }));
                      // }}
                      placeholder={"Select App"}
                      // className={`mb-5`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                  </div>
                </>
              )}
              <p>
                To help us improve delivery, we may survey a small section of
                your audience.
              </p>
              {(selectedObjective === "awareness" ||
                selectedConversionLocation?.label === "Messaging apps" ||
                selectedConversionLocation?.label === "Calls" ||
                selectedConversionLocation?.label === "Facebook Page" ||
                selectedConversionLocation?.label === "Instant Forms" ||
                selectedConversionLocation?.label ===
                  "Instant Forms and Messenger") && (
                <>
                  <h3 className="text-[14px] font-semibold my-3">
                    Facebook Page
                  </h3>
                  <div className="w-full ">
                    <Select
                      // isLoading={adSetLoading}
                      id="goals"
                      options={performanceGoals}
                      // value={adDetails?.publicProfile}
                      // onChange={(e) => {
                      //   setAdDetails((pre) => ({
                      //     ...pre,
                      //     publicProfile: e,
                      //   }));
                      // }}
                      placeholder={"Select Facebook Page"}
                      // className={`mb-5`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                  </div>
                  {selectedConversionLocation?.label === "Messaging apps" && (
                    <>
                      <h3 className="text-[14px] font-semibold my-3">
                        Messaging apps
                      </h3>
                      <p>
                        Choose at least one destination where you are available
                        to chat. If you select multiple apps, we'll send people
                        who tap on your ad to the app where they're most likely
                        to chat with you.
                      </p>
                      {["Messenger", "Instagram", "Whatsapp"]?.map(
                        (platform) => {
                          return (
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      // checked={state.checkbox3}
                                      // onChange={handleChange}
                                      name="checkbox3"
                                    />
                                  }
                                  label=""
                                />
                                <div>
                                  <h3 className="text-[14px] font-semibold my-3">
                                    {platform}
                                  </h3>
                                </div>
                              </div>

                              <button
                                // onClick={(event) => setAnchorEl(event.currentTarget)}
                                className="px-4 py-2 border border-gray-300 mt-3 rounded-lg flex gap-3 items-center w-fit"
                              >
                                Connect Account
                              </button>
                            </div>
                          );
                        }
                      )}
                    </>
                  )}
                </>
              )}
              <label htmlFor="" className="text-[14px] font-semibold my-3">
                Cost per result goal∙ Optional
              </label>
              <div className="relative w-full">
                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                  $
                </span>
                <input
                  type="number"
                  // className="pl-6 p-2 py-3 w-full mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                  className={`pl-6 p-2 py-3 w-full mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                  // value={adSetDetails?.budget}
                  placeholder="No cap"
                  // onChange={(e) =>
                  //   setAdSetDetails((pre) => ({
                  //     ...pre,
                  //     budget: e?.target?.value,
                  //   }))
                  // }
                />
              </div>
              <p className="py-3 ">
                Meta will aim to spend your entire budget and get the most video
                plays (2-second continuous) using the highest-volume bid
                strategy. If keeping the average cost per result around a
                certain amount is important, enter a cost per result goal.
              </p>
              <h3 className="text-[14px] font-semibold my-3">
                {" "}
                When you are charged
              </h3>
              <div className="w-full ">
                <Select
                  // isLoading={adSetLoading}
                  id="goals"
                  options={chargedTypes}
                  // value={adDetails?.publicProfile}
                  // onChange={(e) => {
                  //   setAdDetails((pre) => ({
                  //     ...pre,
                  //     publicProfile: e,
                  //   }));
                  // }}
                  placeholder={""}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
              </div>
              <h3 className="text-[14px] font-semibold my-3"> Delivery Type</h3>
              <div className="w-full ">
                <Select
                  // isLoading={adSetLoading}
                  id="goals"
                  options={chargedTypes}
                  // value={adDetails?.publicProfile}
                  // onChange={(e) => {
                  //   setAdDetails((pre) => ({
                  //     ...pre,
                  //     publicProfile: e,
                  //   }));
                  // }}
                  placeholder={""}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
              </div>
            </div>
            <div
              className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4`}
            >
              <div className="flex items-center justify-between ">
                <label
                  htmlFor=""
                  className="text-[14px] font-semibold mb-3 flex gap-2 items-center"
                >
                  Dynamic creative
                </label>
                <div>
                  <FormControlLabel
                    value=""
                    control={
                      <Switch
                        color="primary"
                        size="medium"
                        // checked={campaignDetails?.status}
                        // onChange={(e) =>
                        //   setCampaignDetials((pre) => ({
                        //     ...pre,
                        //     status: e?.target?.checked,
                        //   }))
                        // }
                      />
                    }
                    label="Off"
                    labelPlacement="start"
                    sx={{
                      margin: "0px",
                      marginTop: `10px`,
                    }}
                  />
                </div>
              </div>
              <p>
                Provide creative elements, such as images and headlines, and
                we'll automatically generate combinations optimised for your
                audience. Variations may include different formats, templates or
                audio based on one or more elements
              </p>
            </div>
            <div
              className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4`}
            >
              <label
                htmlFor=""
                className="text-[16px] font-semibold mb-3 flex gap-2 items-center"
              >
                Budget & schedule
              </label>
              <label
                htmlFor=""
                className="text-[14px] font-semibold mb-3 flex gap-2 items-center"
              >
                Budget
              </label>
              <div className="grid grid-cols-2 gap-6 mb-5">
                <div className="w-full ">
                  <Select
                    // isLoading={adSetLoading}
                    id="public-Profiles"
                    options={budgetTypes}
                    // value={adDetails?.publicProfile}
                    // onChange={(e) => {
                    //   setAdDetails((pre) => ({
                    //     ...pre,
                    //     publicProfile: e,
                    //   }));
                    // }}
                    placeholder={"Select Budget Type"}
                    className={`mt-1`}
                    menuPortalTarget={document.body}
                    styles={selectStyles(currentMode, primaryColor)}
                  />
                </div>
                <div className="flex flex-col">
                  <div className="relative w-full">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                      $
                    </span>
                    <input
                      type="number"
                      // className="pl-6 p-2 py-3 w-full mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                      className={`pl-6 p-2 py-3 w-full mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                      // value={adSetDetails?.budget}
                      placeholder="No cap"
                      // onChange={(e) =>
                      //   setAdSetDetails((pre) => ({
                      //     ...pre,
                      //     budget: e?.target?.value,
                      //   }))
                      // }
                    />
                  </div>
                </div>
              </div>
              <p className="mb-4">
                Your budget was set using Advantage campaign budget and you can
                update your budget at the campaign level.
              </p>
              <label
                htmlFor=""
                className="text-[14px] font-semibold mb-3 flex gap-2 items-center"
              >
                Schedule
              </label>
              <h3 className="text-[14px] font-semibold my-1">Start date</h3>
              <div className="grid grid-cols-2 gap-6 items-center py-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={localData?.startDate || null}
                    label={t("start_date")}
                    views={["day", "month", "year"]}
                    onChange={(val) =>
                      setLocalData((pre) => ({
                        ...pre,
                        startDate: val,
                      }))
                    }
                    format="DD-MM-YYYY"
                    minDate={dayjs()} // Restrict to future dates
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          "& input": {
                            color: currentMode === "dark" ? "white" : "black",
                          },
                          "& .MuiSvgIcon-root": {
                            color: currentMode === "dark" ? "white" : "black",
                          },
                          // "& .MuiOutlinedInput-notchedOutline": {
                          //   borderColor:
                          //     fieldErrors?.date === true &&
                          //     "#DA1F26 !important",
                          // },
                          // marginBottom: "20px",
                        }}
                        fullWidth
                        size="small"
                        {...params}
                        onKeyDown={(e) => e.preventDefault()}
                        readOnly={true}
                      />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Select time"
                    value={localData?.startTime || null}
                    onChange={(newValue) => {
                      setLocalData((pre) => ({
                        ...pre,
                        startTime: newValue,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={handleChange} />
                  }
                  label="Set an End Date (Optional)"
                />
              </div>
              {checked && (
                <>
                  <h3 className="text-[14px] font-semibold my-1">End Date</h3>
                  <div className="grid grid-cols-2 gap-6 items-center py-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={localData?.endDate || null}
                        label={t("end_date")}
                        views={["day", "month", "year"]}
                        onChange={(val) =>
                          setLocalData((pre) => ({
                            ...pre,
                            endDate: val,
                          }))
                        }
                        format="DD-MM-YYYY"
                        minDate={localData?.startDate || dayjs()} // End date should be after start date
                        disabled={!localData?.startDate}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "& input": {
                                color:
                                  currentMode === "dark" ? "white" : "black",
                              },
                              "& .MuiSvgIcon-root": {
                                color:
                                  currentMode === "dark" ? "white" : "black",
                              },
                              // "& .MuiOutlinedInput-notchedOutline": {
                              //   borderColor:
                              //     fieldErrors?.date === true &&
                              //     "#DA1F26 !important",
                              // },
                              // marginBottom: "20px",
                            }}
                            fullWidth
                            size="small"
                            {...params}
                            onKeyDown={(e) => e.preventDefault()}
                            readOnly={true}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Select time"
                        value={localData?.endTime || null}
                        onChange={(newValue) => {
                          setLocalData((pre) => ({
                            ...pre,
                            endTime: newValue,
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </>
              )}
              <h3 className="text-[14px] font-semibold my-1">
                Ad set spending limits
              </h3>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={handleChange} />
                  }
                  label="Ad set spending limits"
                />
              </div>
              <div className="grid grid-cols-2 gap-6 items-center py-4">
                <div>
                  <label htmlFor="" className="text-[14px] font-semibold my-3">
                    Daily minimum
                  </label>
                  <div className="relative w-full">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                      $
                    </span>
                    <input
                      type="number"
                      // className="pl-6 p-2 py-3 w-full mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                      className={`pl-6 p-2 py-3 w-full mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                      // value={adSetDetails?.budget}
                      placeholder="No cap"
                      // onChange={(e) =>
                      //   setAdSetDetails((pre) => ({
                      //     ...pre,
                      //     budget: e?.target?.value,
                      //   }))
                      // }
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="" className="text-[14px] font-semibold my-3">
                    Daily maximum
                  </label>
                  <div className="relative w-full">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                      $
                    </span>
                    <input
                      type="number"
                      // className="pl-6 p-2 py-3 w-full mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                      className={`pl-6 p-2 py-3 w-full mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                      // value={adSetDetails?.budget}
                      placeholder="No cap"
                      // onChange={(e) =>
                      //   setAdSetDetails((pre) => ({
                      //     ...pre,
                      //     budget: e?.target?.value,
                      //   }))
                      // }
                    />
                  </div>
                </div>
              </div>
              <h3 className="text-[14px] font-semibold my-3">
                Budget scheduling
              </h3>
              <div className="flex items-center justify-between ">
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={handleChange} />
                  }
                  label="Increase your budget during specific time periods"
                />
                <button
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                  className="px-3 py-2 border border-gray-200 rounded-lg flex gap-3 items-center"
                >
                  View <TiArrowSortedDown size={16} />
                </button>
              </div>
              <div className="bg-[#C3DCF5] rounded-lg p-3 mt-3">
                <div
                  className={`flex justify-between items-center py-3 text-black`}
                >
                  <p>
                    Tell us the duration of your anticipated high-demand period
                  </p>
                  <span>
                    <IoIosArrowUp size={16} />
                  </span>
                </div>
                <div className={`${getSummaryBgClass()} p-3`}>
                  <Box
                    sx={{
                      ...darkModeColors,
                      "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                        {
                          right: isLangRTL(i18n.language)
                            ? "2.5rem"
                            : "inherit",
                          transformOrigin: isLangRTL(i18n.language)
                            ? "right"
                            : "left",
                        },
                      "& legend": {
                        textAlign: isLangRTL(i18n.language) ? "right" : "left",
                      },
                    }}
                  >
                    <div className="flex justify-between items-center ">
                      <div>
                        <h3 className="text-[14px] font-semibold my-1">
                          Starts On
                        </h3>
                        <div className="grid grid-cols-2 gap-6 items-center py-4">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={localData?.startDate || null}
                              label={t("start_date")}
                              views={["day", "month", "year"]}
                              onChange={(val) =>
                                setLocalData((pre) => ({
                                  ...pre,
                                  startDate: val,
                                }))
                              }
                              format="DD-MM-YYYY"
                              minDate={dayjs()} // Restrict to future dates
                              renderInput={(params) => (
                                <TextField
                                  sx={{
                                    "& input": {
                                      color:
                                        currentMode === "dark"
                                          ? "white"
                                          : "black",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      color:
                                        currentMode === "dark"
                                          ? "white"
                                          : "black",
                                    },
                                    // "& .MuiOutlinedInput-notchedOutline": {
                                    //   borderColor:
                                    //     fieldErrors?.date === true &&
                                    //     "#DA1F26 !important",
                                    // },
                                    // marginBottom: "20px",
                                  }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  onKeyDown={(e) => e.preventDefault()}
                                  readOnly={true}
                                />
                              )}
                            />
                          </LocalizationProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label="Select time"
                              value={localData?.startTime || null}
                              onChange={(newValue) => {
                                setLocalData((pre) => ({
                                  ...pre,
                                  startTime: newValue,
                                }));
                              }}
                              renderInput={(params) => (
                                <TextField {...params} size="small" />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      {/* <div>
                    <FormControlLabel
                      control={
                        <Checkbox checked={checked} onChange={handleChange} />
                      }
                      label="Set an End Date (Optional)"
                    />
                  </div> */}
                      <div>
                        <FaMinus size={16} />
                      </div>
                      <div>
                        {" "}
                        <h3 className="text-[14px] font-semibold my-1">Ends</h3>
                        <div className="grid grid-cols-2 gap-6 items-center py-4">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={localData?.endDate || null}
                              label={t("end_date")}
                              views={["day", "month", "year"]}
                              onChange={(val) =>
                                setLocalData((pre) => ({
                                  ...pre,
                                  endDate: val,
                                }))
                              }
                              format="DD-MM-YYYY"
                              minDate={localData?.startDate || dayjs()} // End date should be after start date
                              disabled={!localData?.startDate}
                              renderInput={(params) => (
                                <TextField
                                  sx={{
                                    "& input": {
                                      color:
                                        currentMode === "dark"
                                          ? "white"
                                          : "black",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      color:
                                        currentMode === "dark"
                                          ? "white"
                                          : "black",
                                    },
                                    // "& .MuiOutlinedInput-notchedOutline": {
                                    //   borderColor:
                                    //     fieldErrors?.date === true &&
                                    //     "#DA1F26 !important",
                                    // },
                                    // marginBottom: "20px",
                                  }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  onKeyDown={(e) => e.preventDefault()}
                                  readOnly={true}
                                />
                              )}
                            />
                          </LocalizationProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label="Select time"
                              value={localData?.endTime || null}
                              onChange={(newValue) => {
                                setLocalData((pre) => ({
                                  ...pre,
                                  endTime: newValue,
                                }));
                              }}
                              renderInput={(params) => (
                                <TextField {...params} size="small" />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                  </Box>

                  <div className="grid grid-cols-2 items-start gap-2">
                    <div className="w-full ">
                      <Select
                        // isLoading={adSetLoading}
                        id="Categories"
                        options={dailyBudgetTypes}
                        // value={adDetails?.publicProfile}
                        // onChange={(e) => {
                        //   setAdDetails((pre) => ({
                        //     ...pre,
                        //     publicProfile: e,
                        //   }));
                        // }}
                        placeholder={"Declare category if applicable"}
                        className={`mb-none`}
                        isMulti={true}
                        menuPortalTarget={document.body}
                        styles={selectStyles(currentMode, primaryColor)}
                      />
                    </div>
                    <input
                      type="number"
                      className={`p-[8px]  ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                      // value={campaignDetails?.campaignName}
                      placeholder="None added"
                      // onChange={(e) =>
                      //   setCampaignDetials((pre) => ({
                      //     ...pre,
                      //     campaignName: e?.target?.value,
                      //   }))
                      // }
                    />
                  </div>
                  <p>
                    Meta will aim to spend an average of Rs656.25 a day (a
                    Rs131.25 increase) from 15 Nov to 16 Nov.
                  </p>
                  <button
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    className="px-4 py-2 border border-gray-300 mt-3 rounded-lg flex gap-3 items-center w-fit"
                  >
                    <MdOutlineDeleteOutline size={16} /> Remove this period
                  </button>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <button
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                  className="px-4 py-2 border border-gray-300 mt-3 rounded-lg flex gap-3 items-center w-fit"
                >
                  <IoAddCircleOutline size={16} /> Add another time period
                </button>
                <span>1/50 entries</span>
              </div>
              <h3 className="text-[14px] font-semibold mt-3">Ad scheduling</h3>
              <p>You'll set the specific schedule within each ad set.</p>
              <div className="">
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={handleChange} />
                  }
                  label="Increase your budget during specific time periods"
                />
              </div>
              <Scheduler />
            </div>

            <div
              className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4`}
            >
              <label
                htmlFor=""
                className="text-[16px] font-semibold mb-3 flex gap-2 items-center"
              >
                Audience controls
              </label>
              <LocationSelector />
              <label
                htmlFor=""
                className="text-[16px] font-semibold mb-3 flex gap-2 items-center"
              >
                Minimum age
              </label>
              <div className="w-full ">
                <Select
                  // isLoading={adSetLoading}
                  id="goals"
                  options={minAges}
                  // value={adDetails?.publicProfile}
                  // onChange={(e) => {
                  //   setAdDetails((pre) => ({
                  //     ...pre,
                  //     publicProfile: e,
                  //   }));
                  // }}
                  placeholder={"Select Min Age"}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
              </div>
              <label
                htmlFor=""
                className="text-[16px] font-semibold mb-3 flex gap-2 items-center"
              >
                Exclude these custom audiences
              </label>
              <div className="w-full ">
                <Select
                  // isLoading={adSetLoading}
                  id="goals"
                  options={minAges}
                  // value={adDetails?.publicProfile}
                  // onChange={(e) => {
                  //   setAdDetails((pre) => ({
                  //     ...pre,
                  //     publicProfile: e,
                  //   }));
                  // }}
                  placeholder={"Select Exclude audiences"}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
              </div>
              <label
                htmlFor=""
                className="text-[16px] font-semibold mb-3 flex gap-2 items-center"
              >
                Languages
              </label>
              <p className="pb-3">
                Only enter a language if you need to limit your audience to
                people who use a language that isn't common to your selected
                locations.
              </p>
              <div
                // className="bg-[#F2F2F2] rounded-lg py-3 px-4"
                className={` ${
                  currentMode === "dark" ? "bg-gray-700" : "bg-[#F2F2F2]"
                } rounded-lg py-3 px-4 mb-3`}
              >
                <div
                  //  className="bg-white flex justify-between items-center px-3 rounded-lg py-3 group"

                  className={` ${
                    currentMode === "dark" ? "bg-black" : "bg-white"
                  } flex justify-between items-center px-3 rounded-lg py-3 group`}
                >
                  <div className="flex gap-4 items-center">English</div>
                  <span className="group-hover:block hidden cursor-pointer">
                    <RxCross2 />
                  </span>
                </div>
              </div>

              <div className="w-full ">
                <Select
                  // isLoading={adSetLoading}
                  id="goals"
                  options={[]}
                  // value={adDetails?.publicProfile}
                  // onChange={(e) => {
                  //   setAdDetails((pre) => ({
                  //     ...pre,
                  //     publicProfile: e,
                  //   }));
                  // }}
                  placeholder={"Select Language"}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
              </div>
            </div>

            <div
              className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4`}
            >
              <Audiences />
            </div>
            <div
              className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4`}
            >
              <label htmlFor="" className="text-[16px] font-semibold my-3">
                Placements
              </label>
              <p>Choose where your ad appears across Meta technologies</p>
              <ul className="pl-4 pt-4 w-1/2 px-4">
                {placementTypes?.map((objective) => {
                  return (
                    <li
                      className={`flex gap-4 items-center mb-8 group cursor-pointer px-2 py-2 rounded-lg  ${
                        selectedDetails?.label === objective?.label
                          ? "bg-[#C3DCF5]"
                          : ""
                      }`}
                      onClick={() => setSelectedDetails(objective)}
                    >
                      {" "}
                      <div
                        className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
                          selectedDetails?.label === objective?.label
                            ? "border-[5px]"
                            : "group-hover:border-[3px]"
                        }  `}
                      ></div>
                      <div className="flex-1">
                        <h3
                          className={`text-[14px] ${
                            currentMode === "dark"
                              ? selectedDetails?.label === objective?.label
                                ? "text-black"
                                : "text-white"
                              : "text-black"
                          }`}
                        >
                          {objective?.label}
                        </h3>
                        <p>{objective?.para}</p>
                      </div>
                    </li>
                  );
                })}
              </ul>
              <label htmlFor="" className="text-[14px] font-semibold my-3">
                Devices
              </label>
              <div className="w-full ">
                <Select
                  // isLoading={adSetLoading}
                  id="goals"
                  options={devices}
                  // value={adDetails?.publicProfile}
                  // onChange={(e) => {
                  //   setAdDetails((pre) => ({
                  //     ...pre,
                  //     publicProfile: e,
                  //   }));
                  // }}
                  placeholder={"All devices (recommended"}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
              </div>
              <label htmlFor="" className="text-[14px] font-semibold my-3">
                Platforms
              </label>
              <FormGroup>
                <div className="grid grid-cols-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        // checked={state.checkbox1}
                        // onChange={handleChange}
                        name="Facebook"
                      />
                    }
                    label="Facebook"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        // checked={state.checkbox2}
                        // onChange={handleChange}
                        name="checkbox2"
                      />
                    }
                    label="Instagram"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        // checked={state.checkbox3}
                        // onChange={handleChange}
                        name="checkbox3"
                      />
                    }
                    label="Audience Network"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        // checked={state.checkbox4}
                        // onChange={handleChange}
                        name="checkbox4"
                      />
                    }
                    label="Messenger"
                  />
                </div>
              </FormGroup>
              <label htmlFor="" className="text-[14px] font-semibold my-3">
                Asset customisation
              </label>
              <p className="text-gray-400">
                18/18 placements that support asset customisation
              </p>
              <label htmlFor="" className="text-[14px] font-semibold my-3">
                Placements
              </label>
              <Placements />
              <label htmlFor="" className="text-[14px] font-semibold my-3">
                Specific mobile devices & operating systems
              </label>
              <div className="w-full ">
                <Select
                  // isLoading={adSetLoading}
                  id="goals"
                  options={mobileDevices}
                  // value={adDetails?.publicProfile}
                  // onChange={(e) => {
                  //   setAdDetails((pre) => ({
                  //     ...pre,
                  //     publicProfile: e,
                  //   }));
                  // }}
                  placeholder={"Select Mobile Device"}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
              </div>
              <label
                htmlFor=""
                className="text-[14px] font-semibold text-gray-400 my-3"
              >
                Included devices
              </label>
              <div
                // className="bg-[#F2F2F2] rounded-lg py-3 px-4 "
                className={` ${
                  currentMode === "dark" ? "bg-gray-700" : "bg-[#F2F2F2]"
                } rounded-lg py-3 px-4`}
              >
                <div
                  //  className="bg-white flex justify-between items-center px-3 rounded-lg py-3 my-2 group"
                  className={` ${
                    currentMode === "dark" ? "bg-black" : "bg-white"
                  } flex justify-between items-center px-3 rounded-lg py-3 my-3 group`}
                >
                  <div className="flex gap-4 items-center">At high school</div>
                  <span className="group-hover:block hidden cursor-pointer">
                    <RxCross2 />
                  </span>
                </div>
                <div
                  // className="bg-white flex justify-between items-center px-3 rounded-lg py-3 my-2 group"
                  className={` ${
                    currentMode === "dark" ? "bg-black" : "bg-white"
                  } flex justify-between items-center px-3 rounded-lg py-3 my-3 group`}
                >
                  <div className="flex gap-4 items-center">
                    At high University
                  </div>
                  <span className="group-hover:block hidden cursor-pointer">
                    <RxCross2 />
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-4 border-b py-5 px-7 flex-1 mb-5">
                <IoSearch size={16} />

                <input
                  type="text"
                  className={`focus:outline-none border-none flex-1 ${
                    currentMode === "dark" ? "bg-dark" : "bg-white"
                  }`}
                  placeholder="Search Mobiles"
                  // onChange={(e) => setSearchTerm(e?.target?.value)}
                  // value={searchTerm}
                />
              </div>
              <label
                htmlFor=""
                className="text-[14px] font-semibold text-gray-400 my-3"
              >
                Excluded devices
              </label>
              <div
                //  className="bg-[#F2F2F2] rounded-lg py-3 px-4 "
                className={` ${
                  currentMode === "dark" ? "bg-gray-700" : "bg-[#F2F2F2]"
                } rounded-lg py-3 px-4`}
              >
                <div
                  //  className="bg-white flex justify-between items-center px-3 rounded-lg py-3 my-2 group"
                  className={` ${
                    currentMode === "dark" ? "bg-black" : "bg-white"
                  } flex justify-between items-center px-3 rounded-lg py-3 my-3 group`}
                >
                  <div className="flex gap-4 items-center">At high school</div>
                  <span className="group-hover:block hidden cursor-pointer">
                    <RxCross2 />
                  </span>
                </div>
                <div
                  //  className="bg-white flex justify-between items-center px-3 rounded-lg py-3 my-2 group"
                  className={` ${
                    currentMode === "dark" ? "bg-black" : "bg-white"
                  } flex justify-between items-center px-3 rounded-lg py-3 my-3 group`}
                >
                  <div className="flex gap-4 items-center">
                    At high University
                  </div>
                  <span className="group-hover:block hidden cursor-pointer">
                    <RxCross2 />
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-4 border-b py-5 px-7 flex-1 mb-5">
                <IoSearch size={16} />

                <input
                  type="text"
                  className={`focus:outline-none border-none flex-1 ${
                    currentMode === "dark" ? "bg-dark" : "bg-white"
                  }`}
                  placeholder="Search Mobiles"
                  // onChange={(e) => setSearchTerm(e?.target?.value)}
                  // value={searchTerm}
                />
              </div>
              <label
                htmlFor=""
                className="text-[14px] font-semibold text-gray-400 my-3"
              >
                OS versions
              </label>
              <div className="grid grid-cols-2 w-[250px] gap-3">
                <Select
                  // isLoading={adSetLoading}
                  id="min Age"
                  // options={Ages}
                  // value={adDetails?.publicProfile}
                  // onChange={(e) => {
                  //   setAdDetails((pre) => ({
                  //     ...pre,
                  //     publicProfile: e,
                  //   }));
                  // }}
                  placeholder={"Min"}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
                <Select
                  // isLoading={adSetLoading}
                  id="max-ages"
                  // options={Ages}
                  // value={adDetails?.publicProfile}
                  // onChange={(e) => {
                  //   setAdDetails((pre) => ({
                  //     ...pre,
                  //     publicProfile: e,
                  //   }));
                  // }}
                  placeholder={"Max"}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={handleChange} />
                  }
                  label="Only when connected to Wi-Fi"
                />
              </div>
              <label htmlFor="" className="text-[10px] font-semibold">
                Exclude skippable ads
              </label>
              <div className="flex gap-3">
                <span>Available for</span>
                <span className="font-semibold text-[10px]">
                  in-stream videos.
                </span>
              </div>

              <div>
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={handleChange} />
                  }
                  label="Exclude skippable ads in the ad set (only for in-stream videos)"
                />
              </div>
              <label htmlFor="" className="text-[16px] font-semibold  my-3">
                Brand safety and suitability
              </label>
              <p>
                Prevent your ads from appearing near content that's not
                conducive to your brand. Manage in Brand safety and suitability
              </p>
              <label htmlFor="" className="text-[14px] font-semibold  my-3">
                Inventory filter
              </label>
              <div className="bg-[#C3DCF5] rounded-lg p-3 mt-3">
                <div
                  className={`flex justify-between items-center py-3 text-black`}
                >
                  <p>
                    Tell us the duration of your anticipated high-demand period
                  </p>
                  <span>
                    <IoIosArrowUp size={16} />
                  </span>
                </div>
                <ul
                  className={`pl-4 pt-4 w-full px-4 grid grid-cols-3 gap-11 ${
                    currentMode === "dark" ? "bg-dark" : "bg-white"
                  }`}
                >
                  {inventoryFilters?.map((objective) => {
                    return (
                      <li
                        className={`flex gap-4 flex-col items-start mb-8 group cursor-pointer px-2 py-2 rounded-lg border  ${
                          selectedDetails?.label === objective?.label
                            ? "border-[#0A78BE]"
                            : ""
                        } `}
                        onClick={() => setSelectedDetails(objective)}
                      >
                        {" "}
                        <div
                          className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
                            selectedDetails?.label === objective?.label
                              ? "border-[5px]"
                              : "group-hover:border-[3px]"
                          }  `}
                        ></div>
                        <div className="flex-1">
                          <h3
                            className={`text-[14px] ${
                              currentMode === "dark"
                                ? "text-white"
                                : "text-black"
                            }`}
                          >
                            {objective?.label}
                          </h3>
                          <p>{objective?.para}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div
              className={`flex justify-end py-5 ${getSummaryBgClass()} shadow-md px-4 mt-3`}
              // style={{ width: "calc(100% - 40px)" }}
            >
              <div className="flex items-center justify-end gap-3 w-full pt-3 border-t-[2px]">
                <button
                  // onClick={() => setCreateAdSetModal(false)}
                  className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium ${
                    currentMode == "dark" ? "bg-black text-white" : "bg-white"
                  } `}
                >
                  Cancel
                </button>
                <button
                  // onClick={() => {
                  //   setCreateAdSetModal(false);
                  //   // setIsSingleAdSet(selectedCampaign?.value);
                  //   setIsSingleAdSet(1);
                  //   setAdSetDetails(null);
                  // }}
                  className="p-3 px-7 rounded-[5px]  text-white bg-[#0A78BE]  border hover:border-[#F7D901]"
                  // disabled={!selectedCampaign}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <TimeScheduleView anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
};

export default SingleAdSet;
