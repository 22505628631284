import React, { useEffect, useState, useRef } from "react";

import { TiArrowSortedDown } from "react-icons/ti";
import {
  TextField,
  CircularProgress,
  Tooltip,
  Modal,
  Backdrop,
  InputAdornment,
  FormControlLabel,
  Switch,
  Checkbox,
} from "@mui/material";
import { useStateContext } from "../../context/ContextProvider";
import { MdClose } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import { selectStyles } from "../_elements/SelectStyles.jsx";
import Select from "react-select";
import TimeScheduleView from "./TimeScheduleView.js";
import { FaMinus } from "react-icons/fa";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { IoIosArrowUp } from "react-icons/io";
import { IoAddCircleOutline } from "react-icons/io5";
import { buying_type } from "../_elements/SelectOptions.js";
import utc from "dayjs/plugin/utc";

const style = {
  transform: "translate(0%, 0%)",
  boxShadow: 24,
};
const stripeStyle = {
  backgroundColor: "#e5e7eb", // Light gray background
  backgroundImage:
    "linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent)",
  backgroundSize: "20px 20px", // Size of the stripes
};
const token = localStorage?.getItem("auth-token");
const categories = [
  {
    label: "Financial products and services (formerly Credit)",
    value: "FINANCIAL_PRODUCTS_SERVICES",
  },
  {
    label: "Employment",
    value: "EMPLOYMENT",
  },
  {
    label: "Housing",
    value: "HOUSING",
  },
  {
    label: "Social issues, elections or politics",
    value: "ISSUES_ELECTIONS_POLITICS",
  },
];
const budgetTypes = [
  {
    label: "Daily Budget",
    value: "daily_budget",
  },
  {
    label: "Lifetime Budget",
    value: "lifetime_budget",
  },
];
const bidStrategies = [
  {
    label: "Highest volume",
    value: "LOWEST_COST_WITHOUT_CAP", // Aligning with the enum for automatic bidding
    description:
      "Get the most results for your budget without limiting your bid amount. Best for cost efficiency but might lack stable average costs.",
  },
  {
    label: "Cost per result goal",
    value: "COST_CAP", // Aligning with the enum for cost cap
    description:
      "Control your cost per optimization event while aiming for results. May require careful cost target settings.",
  },
  {
    label: "Bid Cap",
    value: "LOWEST_COST_WITH_BID_CAP", // Aligning with the enum for manual maximum-cost bidding
    description:
      "Limit actual bids to your specified amount. Offers control over costs but may reduce ad delivery if limits are too low.",
  },
];

const dailyBudgetTypes = [
  {
    label: "Increase daily budget by value amount (Rs) ",
    value: "ABSOLUTE",
  },
  {
    label: "Increase daily budget by value percentage (%)",
    value: "MULTIPLIER",
  },
];
const SingleCampaign = ({
  isSingleCampaign,
  setIsSingleCampaign,
  setCreateCampaignModal,
  oldObjectives,
  currentCampaignDetails,
  campData,
  setCampData,
  createCampaign,
  btnLoading,
}) => {
  const {
    isLangRTL,
    BACKEND_URL,
    i18n,
    currentMode,
    t,
    snapchatCredentials,
    themeBgImg,
    darkModeColors,
    primaryColor,
  } = useStateContext();
  const [isClosing, setIsClosing] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isChangesOccured, setIsChangesOccured] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [advantageCamp, setAdvantageCamp] = useState(false);
  const [budget, setBudget] = useState(null);
  const [scheduleBudgetType, setScheduleBudgetType] = useState("ABSOLUTE");
  const [scheduleBudget, setScheduleBudget] = useState("");
  const [scheduleBudgetForm, setScheduleBudgetForm] = useState(true);

  const [campaignDetails, setCampaignDetials] = useState({
    campaignName: "",
    status: false,
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    dailySpendCap: null,
    lifeTimeSpendCap: null,
  });
  const [localData, setLocalData] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  });
  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (checked == false) {
      setCampData({
        ...campData,
        budget_schedule_specs: "",
      });
    }
  };
  const handleClose = () => {
    setIsClosing(true);

    setTimeout(() => {
      setIsClosing(false);
      setIsSingleCampaign(false);
    }, 1000);
  };

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  const handleBudgetScheduleUpdate = () => {
    dayjs.extend(utc);

    if (
      localData.startDate &&
      localData.startTime &&
      localData.endDate &&
      localData.endTime
    ) {
      const timeStart = dayjs(localData.startDate)
        .hour(dayjs(localData.startTime).hour())
        .minute(dayjs(localData.startTime).minute())
        .utc()
        .unix(); // Convert to UNIX timestamp in seconds

      const timeEnd = dayjs(localData.endDate)
        .hour(dayjs(localData.endTime).hour())
        .minute(dayjs(localData.endTime).minute())
        .utc()
        .unix(); // Convert to UNIX timestamp in seconds

      const budgetValue = scheduleBudget;
      const budgetValueType = scheduleBudgetType;

      const budgetScheduleSpecs = [
        {
          time_start: timeStart,
          time_end: timeEnd,
          budget_value: budgetValue,
          budget_value_type: budgetValueType,
        },
      ];

      setCampData((prev) => ({
        ...prev,
        budget_schedule_specs: budgetScheduleSpecs,
      }));
    } else {
      console.error("Start and End date-time must be provided");
    }
  };

  useEffect(() => {
    handleBudgetScheduleUpdate();
  }, [
    localData.startDate,
    localData.startTime,
    localData.endDate,
    localData.endTime,
    scheduleBudget,
    scheduleBudgetType,
  ]);

  useEffect(() => {
    if (budget == "daily_budget") {
      setCampData((pre) => ({
        ...pre,
        daily_budget: pre.lifetime_budget,
        lifetime_budget: "",
      }));
    } else {
      setCampData((pre) => ({
        ...pre,
        lifetime_budget: pre.daily_budget,
        daily_budget: "",
      }));
    }
  }, [budget]);

  return (
    <>
      <Modal
        keepMounted
        open={isSingleCampaign}
        onClose={() => setIsSingleCampaign(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={`${
            isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
          } ${
            isClosing
              ? isLangRTL(i18n.language)
                ? "modal-close-left"
                : "modal-close-right"
              : ""
          }
        w-[100vw] h-[100vh] flex items-start justify-end `}
        >
          <button
            // onClick={handleCloseTimelineModel}
            onClick={handleClose}
            className={`${
              isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
            }
            bg-primary w-fit h-fit p-3 my-4 z-10`}
          >
            <MdClose
              size={18}
              color={"white"}
              className=" hover:border hover:border-white hover:rounded-full"
            />
          </button>

          <div
            style={style}
            className={` ${getSummaryBgClass()} ${
              currentMode === "dark"
                ? ""
                : "bg-gradient-to-br from-[#F3EEF0] to-[#E7F0F9]"
            } ${
              isLangRTL(i18n.language)
                ? currentMode === "dark" && " border-primary border-r-2"
                : currentMode === "dark" && " border-primary border-l-2"
            } 
             pt-4 h-[100vh] w-[80vw] overflow-y-scroll border-primary px-7 flex flex-col 
            `}
          >
            <h2 className="font-bold text-[20px] mb-5">Build Your Campaign</h2>
            <div className={`  rounded-3xl px-8 py-4 flex-1`}>
              <div
                className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4`}
              >
                <label htmlFor="" className="text-[14px] font-semibold mb-3">
                  Campaign Name
                </label>
                <input
                  type="text"
                  className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                  value={campData?.name}
                  onChange={(e) =>
                    setCampData((pre) => ({
                      ...pre,
                      name: e?.target?.value,
                    }))
                  }
                />
              </div>
              <div
                className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4 my-5`}
              >
                <h3 className="text-[14px] font-semibold my-3">Categories</h3>
                <div className="w-full ">
                  <Select
                    // isLoading={adSetLoading}
                    id="Categories"
                    options={categories}
                    value={categories?.filter((cat) =>
                      campData?.special_ad_categories?.includes(cat?.value)
                    )}
                    onChange={(selectedOptions) => {
                      setCampData((prev) => ({
                        ...prev,
                        special_ad_categories: selectedOptions?.map(
                          (option) => option?.value
                        ),
                      }));
                    }}
                    placeholder={"Declare category if applicable"}
                    // className={`mb-5`}
                    isMulti={true}
                    menuPortalTarget={document.body}
                    styles={selectStyles(currentMode, primaryColor)}
                  />
                </div>
                {/* <h3 className="text-[14px] font-semibold my-3">Countries</h3>
                <div className="w-full ">
                  <Select
                    // isLoading={adSetLoading}
                    id="Categories"
                    options={categories}
                    // value={adDetails?.publicProfile}
                    // onChange={(e) => {
                    //   setAdDetails((pre) => ({
                    //     ...pre,
                    //     publicProfile: e,
                    //   }));
                    // }}
                    placeholder={"Select Categories"}
                    // className={`mb-5`}
                    isMulti={true}
                    menuPortalTarget={document.body}
                    styles={selectStyles(currentMode, primaryColor)}
                  />
                </div> */}
              </div>
              <div
                className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4 my-5`}
              >
                <h3 className="text-[14px] font-semibold my-3">Buying Type</h3>
                <div className="w-full ">
                  <Select
                    // isLoading={adSetLoading}
                    id="public-Profiles"
                    options={buying_type(t)}
                    value={buying_type(t)?.find(
                      (type) => type.value == campData?.buying_type
                    )}
                    onChange={(e) => {
                      setCampData((pre) => ({
                        ...pre,
                        buying_type: e.value,
                      }));
                    }}
                    placeholder={"Buying Type"}
                    // className={`mb-5`}
                    menuPortalTarget={document.body}
                    styles={selectStyles(currentMode, primaryColor)}
                  />
                </div>
                <span className="text-[14px] font-semibold">Objective</span>
                <div className="flex gap-3 py-6 items-center">
                  <img src={isSingleCampaign?.banner} className="w-[40px] " />
                  <div className="flex-1">
                    <h4>{isSingleCampaign?.label}</h4>
                    <p>{isSingleCampaign?.content?.para}</p>
                  </div>
                  {!currentCampaignDetails && (
                    <button
                      className="flex gap-2 text-[#0894FA]"
                      onClick={() => setCreateCampaignModal(true)}
                    >
                      <MdModeEdit color="#0894FA" />
                      Change
                    </button>
                  )}
                </div>
                <div className={`flex flex-col `}>
                  <label htmlFor="" className="text-[14px] font-semibold mb-3">
                    Campaign spending limit
                  </label>
                  <input
                    type="number"
                    className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                    value={campData?.spend_cap}
                    placeholder="None added"
                    onChange={(e) =>
                      setCampData((pre) => ({
                        ...pre,
                        spend_cap: e?.target?.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div
                className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4 my-5`}
              >
                <div className="flex items-center justify-between ">
                  <label
                    htmlFor=""
                    className="text-[14px] font-semibold mb-3 flex gap-2 items-center"
                  >
                    Advantage campaign budget{" "}
                    <div
                      class="bg-[url('https://static.xx.fbcdn.net/rsrc.php/v3/yv/r/cX7DKT2CkS9.png?_nc_eui2=AeH5yspQWneUptvKil0lNs-PnyzUMinCbu6fLNQyKcJu7gbOaCxldSmzbz6EmiGhDyELMYzohemRX9EswFsBy_xw')] 
         bg-[length:auto] 
         bg-no-repeat 
         bg-[position:-249px_-356px] 
         w-[12px] 
         h-[12px] 
         inline-block"
                    ></div>
                  </label>
                  <div>
                    <FormControlLabel
                      value=""
                      control={
                        <Switch
                          color="primary"
                          size="medium"
                          checked={advantageCamp}
                          onChange={(e) => setAdvantageCamp(!advantageCamp)}
                        />
                      }
                      label={advantageCamp ? "On" : "Off"}
                      labelPlacement="start"
                      sx={{
                        margin: "0px",
                        marginTop: `10px`,
                      }}
                    />
                  </div>
                </div>
                {advantageCamp ? (
                  <>
                    <p>
                      Advantage campaign budget will distribute your budget
                      across currently delivering ad sets to get more results
                      depending on your performance goal choices and bid
                      strategy. You can control spending on each ad set.
                    </p>
                    <h3 className="text-[14px] font-semibold my-3">
                      Campaign budget
                    </h3>
                    <div className="grid grid-cols-2 gap-6 mb-5">
                      <div className="w-full ">
                        <Select
                          id="public-Profiles"
                          options={budgetTypes}
                          value={budgetTypes.find(
                            (type) => type.value === budget
                          )}
                          onChange={(e) => {
                            if (e.value == "daily_budget") {
                              setBudget("daily_budget");
                            } else {
                              setBudget("lifetime_budget");
                              setCampData({
                                ...campData,
                                spend_cap: "",
                              });
                            }
                          }}
                          placeholder={"Select Budget Type"}
                          className={`mt-1`}
                          menuPortalTarget={document.body}
                          styles={selectStyles(currentMode, primaryColor)}
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="relative w-full">
                          <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                            $
                          </span>
                          <input
                            type="number"
                            // className="pl-6 p-2 py-3 w-full mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                            className={`pl-6 p-2 py-3 w-full mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                            value={
                              budget === "daily_budget"
                                ? campData?.daily_budget || ""
                                : campData?.lifetime_budget || ""
                            }
                            placeholder="No cap"
                            onChange={(e) => {
                              if (budget == "daily_budget") {
                                setCampData((pre) => ({
                                  ...pre,
                                  daily_budget: e.target.value,
                                  lifetime_budget: "",
                                }));
                              } else {
                                setCampData((pre) => ({
                                  ...pre,
                                  lifetime_budget: e.target.value,
                                  daily_budget: "",
                                }));
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <h3 className="text-[14px] font-semibold my-3">
                      Campaign bid strategy
                    </h3>
                    <div className="w-full ">
                      <Select
                        // isLoading={adSetLoading}
                        id="public-Profiles"
                        options={bidStrategies}
                        value={bidStrategies?.find(
                          (bidStr) => bidStr?.value == campData?.bid_strategy
                        )}
                        onChange={(e) => {
                          setCampData((pre) => ({
                            ...pre,
                            bid_strategy: e.value,
                          }));
                        }}
                        placeholder={"Select Bid Strategy"}
                        // className={`mb-5`}
                        menuPortalTarget={document.body}
                        styles={selectStyles(currentMode, primaryColor)}
                      />
                    </div>
                    {budget == "daily_budget" ? (
                      <>
                        <h3 className="text-[14px] font-semibold my-3">
                          Budget scheduling
                        </h3>
                        <div className="flex items-center justify-between ">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checked}
                                onChange={handleChange}
                              />
                            }
                            label="Increase your budget during specific time periods"
                          />
                          {/* <button
                            onClick={(event) =>
                              setAnchorEl(event.currentTarget)
                            }
                            className="px-3 py-2 border border-gray-200 rounded-lg flex gap-3 items-center"
                          >
                            View <TiArrowSortedDown size={16} />
                          </button> */}
                        </div>
                        {checked ? (
                          <>
                            {scheduleBudgetForm ? (
                              <>
                                <div className="bg-[#C3DCF5] rounded-lg p-3 mt-3">
                                  <div
                                    className={`flex justify-between items-center py-3 text-black`}
                                  >
                                    <p>
                                      Tell us the duration of your anticipated
                                      high-demand period
                                    </p>
                                    <span>
                                      <IoIosArrowUp size={16} />
                                    </span>
                                  </div>

                                  <div className={`${getSummaryBgClass()} p-3`}>
                                    <Box
                                      sx={{
                                        ...darkModeColors,
                                        "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                                          {
                                            right: isLangRTL(i18n.language)
                                              ? "2.5rem"
                                              : "inherit",
                                            transformOrigin: isLangRTL(
                                              i18n.language
                                            )
                                              ? "right"
                                              : "left",
                                          },
                                        "& legend": {
                                          textAlign: isLangRTL(i18n.language)
                                            ? "right"
                                            : "left",
                                        },
                                      }}
                                    >
                                      <div className="flex justify-between items-center ">
                                        <div>
                                          <h3 className="text-[14px] font-semibold my-1">
                                            Starts On
                                          </h3>
                                          <div className="grid grid-cols-2 gap-6 items-center py-4">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DatePicker
                                                value={
                                                  localData?.startDate || null
                                                }
                                                label={t("start_date")}
                                                views={["day", "month", "year"]}
                                                onChange={(val) =>
                                                  setLocalData((pre) => ({
                                                    ...pre,
                                                    startDate: val,
                                                  }))
                                                }
                                                format="DD-MM-YYYY"
                                                minDate={dayjs()} // Restrict to future dates
                                                renderInput={(params) => (
                                                  <TextField
                                                    sx={{
                                                      "& input": {
                                                        color:
                                                          currentMode === "dark"
                                                            ? "white"
                                                            : "black",
                                                      },
                                                      "& .MuiSvgIcon-root": {
                                                        color:
                                                          currentMode === "dark"
                                                            ? "white"
                                                            : "black",
                                                      },
                                                      // "& .MuiOutlinedInput-notchedOutline": {
                                                      //   borderColor:
                                                      //     fieldErrors?.date === true &&
                                                      //     "#DA1F26 !important",
                                                      // },
                                                      // marginBottom: "20px",
                                                    }}
                                                    fullWidth
                                                    size="small"
                                                    {...params}
                                                    onKeyDown={(e) =>
                                                      e.preventDefault()
                                                    }
                                                    readOnly={true}
                                                  />
                                                )}
                                              />
                                            </LocalizationProvider>
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <TimePicker
                                                label="Select time"
                                                value={
                                                  localData?.startTime || null
                                                }
                                                onChange={(newValue) => {
                                                  setLocalData((pre) => ({
                                                    ...pre,
                                                    startTime: newValue,
                                                  }));
                                                }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    size="small"
                                                  />
                                                )}
                                              />
                                            </LocalizationProvider>
                                          </div>
                                        </div>

                                        <div>
                                          <FaMinus size={16} />
                                        </div>
                                        <div>
                                          {" "}
                                          <h3 className="text-[14px] font-semibold my-1">
                                            Ends
                                          </h3>
                                          <div className="grid grid-cols-2 gap-6 items-center py-4">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DatePicker
                                                value={
                                                  localData?.endDate || null
                                                }
                                                label={t("end_date")}
                                                views={["day", "month", "year"]}
                                                onChange={(val) =>
                                                  setLocalData((pre) => ({
                                                    ...pre,
                                                    endDate: val,
                                                  }))
                                                }
                                                format="DD-MM-YYYY"
                                                minDate={
                                                  localData?.startDate ||
                                                  dayjs()
                                                } // End date should be after start date
                                                disabled={!localData?.startDate}
                                                renderInput={(params) => (
                                                  <TextField
                                                    sx={{
                                                      "& input": {
                                                        color:
                                                          currentMode === "dark"
                                                            ? "white"
                                                            : "black",
                                                      },
                                                      "& .MuiSvgIcon-root": {
                                                        color:
                                                          currentMode === "dark"
                                                            ? "white"
                                                            : "black",
                                                      },
                                                      // "& .MuiOutlinedInput-notchedOutline": {
                                                      //   borderColor:
                                                      //     fieldErrors?.date === true &&
                                                      //     "#DA1F26 !important",
                                                      // },
                                                      // marginBottom: "20px",
                                                    }}
                                                    fullWidth
                                                    size="small"
                                                    {...params}
                                                    onKeyDown={(e) =>
                                                      e.preventDefault()
                                                    }
                                                    readOnly={true}
                                                  />
                                                )}
                                              />
                                            </LocalizationProvider>
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <TimePicker
                                                label="Select time"
                                                value={
                                                  localData?.endTime || null
                                                }
                                                onChange={(newValue) => {
                                                  setLocalData((pre) => ({
                                                    ...pre,
                                                    endTime: newValue,
                                                  }));
                                                }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    size="small"
                                                  />
                                                )}
                                              />
                                            </LocalizationProvider>
                                          </div>
                                        </div>
                                      </div>
                                    </Box>

                                    <div className="grid grid-cols-2 items-start gap-2">
                                      <div className="w-full ">
                                        <Select
                                          // isLoading={adSetLoading}
                                          id="Categories"
                                          options={dailyBudgetTypes}
                                          value={dailyBudgetTypes?.find(
                                            (bug) =>
                                              bug?.value == scheduleBudgetType
                                          )}
                                          onChange={(e) => {
                                            setScheduleBudgetType(e.value);
                                          }}
                                          placeholder={
                                            "Declare category if applicable"
                                          }
                                          className={`mb-none`}
                                          // isMulti={true}
                                          menuPortalTarget={document.body}
                                          styles={selectStyles(
                                            currentMode,
                                            primaryColor
                                          )}
                                        />
                                      </div>
                                      <input
                                        type="number"
                                        className={`p-[8px]  ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                                        value={scheduleBudget}
                                        placeholder="Budget"
                                        onChange={(e) =>
                                          setScheduleBudget(e.target.value)
                                        }
                                      />
                                    </div>
                                    <button
                                      onClick={(event) => {
                                        setScheduleBudgetForm(false);
                                        setCampData({
                                          ...campData,
                                          budget_schedule_specs: "",
                                        });
                                      }}
                                      className="px-4 py-2 border border-gray-300 mt-3 rounded-lg flex gap-3 items-center w-fit"
                                    >
                                      <MdOutlineDeleteOutline size={16} />{" "}
                                      Remove this period
                                    </button>
                                  </div>
                                </div>
                              </>
                            ) : null}
                            {scheduleBudgetForm == false ? (
                              <div className="flex justify-between items-center">
                                <button
                                  onClick={(event) =>
                                    setScheduleBudgetForm(true)
                                  }
                                  className="px-4 py-2 border border-gray-300 mt-3 rounded-lg flex gap-3 items-center w-fit"
                                >
                                  <IoAddCircleOutline size={16} /> Add time
                                  period
                                </button>
                                {/* <span>1/50 entries</span> */}
                              </div>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    ) : budget == "lifetime_budget" ? (
                      <>
                        {/* <h3 className="text-[14px] font-semibold mt-3">
                          Ad scheduling
                        </h3>
                        <p>
                          You'll set the specific schedule within each ad set.
                        </p>
                        <div className="">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checked}
                                onChange={handleChange}
                              />
                            }
                            label="Increase your budget during specific time periods"
                          />
                        </div> */}
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
            {/* <div
              className={`flex justify-end py-5 ${getSummaryBgClass()} shadow-md px-4 mt-3`}
            >
              {!currentCampaignDetails && (
                <button
                  // onClick={() => createCampaign()}
                  // className="p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold bg-white"
                  className={`p-3 px-5 hover:border-[#F7D901] rounded-[20px] border disabled:cursor-not-allowed font-semibold ${
                    currentMode == "dark" ? "bg-black text-white" : "bg-white"
                  } `}
                  disabled={
                    !campData?.campaignName ||
                    !localData?.startDate ||
                    !localData?.startTime
                    //  ||
                    // (checked && (!localData?.endDate || !localData?.endTime))
                  }
                >
                  Next
                </button>
              )}
              {currentCampaignDetails && (
                <button
                  style={
                    isChangesOccured
                      ? { backgroundColor: "black", color: "white" }
                      : currentMode === "dark"
                      ? { backgroundColor: "#282b30", color: "white" }
                      : stripeStyle
                  }
                  // className="p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold bg-white"
                  className={`p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold ${
                    currentMode == "dark" ? "bg-black text-white" : "bg-white"
                  } disabled:cursor-not-allowed `}
                  disabled={!isChangesOccured}
                  // onClick={() => updateCampaign()}
                >
                  Save Changes
                </button>
              )}
            </div> */}
            <div
              className={`flex items-center justify-between gap-3 w-full pt-3 border-t-[2px] ${getSummaryBgClass()} py-3 px-3 mx-4`}
            >
              {/* <button
                // onClick={() => setCreateCampaignModal(false)}
                className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium ${
                  currentMode == "dark" ? "bg-black text-white" : "bg-white"
                } `}
              >
                Cancel
              </button> */}
              <button
                onClick={() => createCampaign()}
                className="p-3 px-7 rounded-[5px]  text-white bg-[#0A78BE]  border hover:border-[#F7D901]"
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <CircularProgress sx={{ color: "white", size: "10px" }} />
                ) : (
                  <span>Create</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <TimeScheduleView anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
};

export default SingleCampaign;
