import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader";
import { useStateContext } from "../../context/ContextProvider";
import { useParams } from "react-router-dom";
import Compaigns from "../../Components/facebookMarketing/manageAds";
const pages = {};

const FacebookMarketing = () => {
  const [loading, setloading] = useState(false);
  const params = useParams();
  const {
    currentMode,
    setopenBackDrop,
    BACKEND_URL,
    pageState,
    t,
    themeBgImg,
  } = useStateContext();

  const { module } = params;
  const pages = {
    manageAds: <Compaigns />,
  };

  console.log("Booked State: ", pageState);

  return (
    <>
      <div className="flex min-h-screen ">
        {loading ? (
          <Loader />
        ) : (
          <div
            className={`w-full mt-2 ${
              !themeBgImg &&
              (currentMode === "dark" ? "bg-dark" : "bg-[#F9F9FA]")
            }`}
          >
            {pages[module]}
          </div>
        )}
      </div>
    </>
  );
};

export default FacebookMarketing;
